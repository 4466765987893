import NegocioService from "@/api/negocios/negocios";

const NegocioStore = {
  state: {
    negocioList: [],
    form: {
      razon_social: null,
      ruc_ci: null,
      direccion: null
    },
  },
  mutations: {
    SET_NEGOCIO_LIST: (state, negocioList) => (state.negocioList = negocioList),
    SET_NEGOCIO: (state, payload) => (state.form = payload),
  },
  actions: {
    async getNegocioList({ dispatch, getters }, { search, page, pageSize }) {
      const lang = getters.getLang;
      const res = await NegocioService.list(search, page, pageSize, lang);

      switch (res?.status) {
        case 401:
          dispatch("redirect", "/auth/login");
          break;
        case 404:
          dispatch("redirect", "/");
          break;
        default:
          return res;
      }
    },
    async saveNegocio({ state, dispatch }) {
      const res = state.form.id ? await NegocioService.edit(state.form) : await NegocioService.create(state.form);

      switch (res.status) {
        case 401:
          dispatch("redirect", "/auth/login");
          break;
        case 404:
          dispatch("redirect", "/");
          break;
        default:
          return res;
      }
    },
    async getNegocioById({ commit, dispatch }, { id }) {
      const res = await NegocioService.getById({ id });
      let data = null;

      switch (res.status) {
        case 401:
          dispatch("redirect", "/auth/login");
          break;
        case 404:
          dispatch("redirect", "/");
          break;
        default:
          data = await res.json();
          commit("SET_NEGOCIO", data);

          return data;
      }
    },
    setNegocioList({ commit }, list) { commit('SET_NEGOCIO_LIST', list) },
    setNegocio({ commit }, payload) { commit('SET_NEGOCIO', payload) },
    async deactivateNegocio ({ dispatch }, { id }) {
      const res = await NegocioService.deactivate({ id });
      
      switch (res?.status) {
        case 401:
          dispatch("redirect", "/auth/login");
          break;
        case 404:
          dispatch("redirect", "/");
          break;
        default:
          return res;
      }
    }
  },
  getters: {
    getNegocioListState: (state) => state.negocioList
  },
};

export default NegocioStore;
