<template>
  <crud-base
    :title="title"
    :popup-data="popupData"
    :show-popup="showPopup"
    @cancel="returnToList()"
    @add-register="save"
    @ok="popupData.type === 'error' ? (showPopup = false) : returnToList()"
  >
    <template v-slot:form>
      <div class="flex-auto lg:px-2 py-2 pt-4">
        <form>
          <div class="flex flex-wrap">
            <div class="w-full lg:w-6/12 px-2">
              <custom-input
                :label="$t('forms.user.fields.firstName')"
                :disabled="isOnlyView"
                :placeholder="$t('forms.user.fieldsPlaceholder.firstName')"
                :validations="'required'"
                name="firstName"
                type="text"
                v-model="user.first_name"
                :show-error-message="!user.first_name"
                :error-message="'El campo es requerido'"
              />
            </div>
            <div class="w-full lg:w-6/12 px-2">
              <custom-input
                :label="$t('forms.user.fields.lastName')"
                :disabled="isOnlyView"
                :placeholder="$t('forms.user.fieldsPlaceholder.lastName')"
                type="text"
                v-model="user.last_name"
                :validations="'required'"
                :show-error-message="!user.last_name"
                :error-message="'El campo es requerido'"
              />
            </div>
          </div>
          <div class="flex flex-wrap">
            <div class="w-full lg:w-6/12 px-2">
              <custom-input
                :label="$t('forms.user.fields.email')"
                :placeholder="$t('forms.user.fieldsPlaceholder.email')"
                :disabled="isOnlyView"
                :upperCase="false"
                type="text"
                v-model="user.email"
                :validations="'required|email'"
              />
            </div>
            <div class="w-full lg:w-6/12 px-2">
              <custom-input
                :label="$t('forms.user.fields.username')"
                :disabled="isOnlyView"
                :placeholder="$t('forms.user.fieldsPlaceholder.username')"
                :upperCase="false"
                type="text"
                autocomplete="username"
                v-model="user.username"
                :validations="'required'"
              />
            </div>
          </div>
          <div class="flex flex-wrap">
            <div class="w-full lg:w-6/12 px-2">
              <custom-input
                v-if="!isEditView || isOnlyView"
                :label="$t('forms.user.fields.password')"
                :disabled="isOnlyView"
                :placeholder="$t('forms.user.fieldsPlaceholder.password')"
                type="password"
                autocomplete="new-password"
                v-model="user.password1"
                :validations="'required'"
                :show-error-message="passwordError !== null"
                :error-message="passwordError"
              />
            </div>
            <div class="w-full lg:w-6/12 px-2">
              <custom-input
                v-if="!isEditView || isOnlyView"
                :label="$t('forms.user.fields.confirmPassword')"
                :disabled="isOnlyView"
                type="password"
                :placeholder="
                  $t('forms.user.fieldsPlaceholder.confirmPassword')
                "
                v-model="user.password2"
                :validations="'required'"
                autocomplete="new-password"
                :show-error-message="confirmPasswordError !== null"
                :error-message="confirmPasswordError"
              />
            </div>
          </div>
          <div class="flex flex-wrap">
            <div
              class="w-full lg:w-6/12 px-2"
              :class="{
                'text-red-500 text-xs italic font-weight-bold mt-1':
                  isInvalidCompany,
              }"
            >
              <label
                class="w-full block text-blueGray-600 text-xs font-bold mb-2"
              >
                {{ $t("forms.user.fields.empresa") }}
              </label>
              <multiselect
                v-model="user.empresa"
                :placeholder="$t('forms.user.fieldsPlaceholder.empresa')"
                :disabled="isOnlyView"
                label="razon_social"
                :internal-search="false"
                :clear-on-select="false"
                :allow-empty="false"
                :options-limit="300"
                :options="companyList"
                @close="onTouchCompany"
                @search-change="findCompany"
              />
              <label
                class="typo__label form__label"
                v-show="isInvalidCompany"
                >{{ $t("message.errorMessage.required") }}</label
              >
            </div>
            <div
              class="w-full lg:w-6/12 px-2 pt-4"
              :class="{
                'text-red-500 text-xs italic font-weight-bold mt-1':
                  isInvalidGroup,
              }"
            >
              <label
                class="w-full block text-blueGray-600 text-xs font-bold mb-2"
              >
                {{ $t("forms.user.fields.grupo") }}
              </label>
              <multiselect
                v-model="user.groups"
                :placeholder="$t('forms.user.fieldsPlaceholder.group')"
                :disabled="isOnlyView"
                label="name"
                :internal-search="false"
                :clear-on-select="false"
                :options-limit="300"
                :options="groupList"
                @close="onTouchGroup"
                @search-change="findGroup"
              />
              <label class="typo__label form__label" v-show="isInvalidGroup">{{
                $t("message.errorMessage.required")
              }}</label>
            </div>
          </div>
          <div class="flex flex-wrap">
            <div class="w-full lg:w-6/12 px-2 mt-1">
              <label
                class="w-full block text-blueGray-600 text-xs font-bold mb-2"
              >
                {{ $t("forms.idiomas.title") }}
              </label>
              <idiomas-multiselect
                v-model="user.idioma"
                validation="required"
              />
            </div>
            <div class="flex lg:w-1 px-2 py-2 flex-col">
              <label
                class="block text-blueGray-600 text-xs font-bold mr-2 mb-2"
                >Es administrador?</label
              >
              <input
                id="checkEsSuperusuario"
                type="checkbox"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150"
                v-model="user.is_superuser"
                :disabled="isOnlyView"
              />
            </div>
          </div>
        </form>
      </div>
    </template>
  </crud-base>
</template>
<script>
import CrudBase from "@/components/Cards/CrudBase.vue";
import CustomInput from "@/components/Inputs/CustomInput.vue";
import IdiomasMultiselect from "@/views/idiomas/IdiomasMultiselect.vue";
import { baseMixin } from "@/mixins/base";
import Multiselect from "vue-multiselect";
import { mapActions } from "vuex";

export default {
  components: {
    CrudBase,
    CustomInput,
    Multiselect,
    IdiomasMultiselect,
  },
  data() {
    return {
      module: "user",
      capitalizeModule: "User",
      listName: "ListUsers",
      isTouchedCompany: false,
      isTouchedGroup: false,
      companyList: [],
      groupList: [],
      user: {
        is_superuser: false,
      },
    };
  },
  mixins: [baseMixin],
  computed: {
    isInvalidCompany() {
      return this.isTouchedCompany && this.user.empresa === null;
    },
    isInvalidGroup() {
      return this.isTouchedGroup && this.user.groups === null;
    },
    passwordError() {
      const password = this.user.password1 ?? ""; // Asigna una cadena vacía si es null
      const regex = /^(?=.*[A-Z])(?=.*[!@#$%^&*()_+[\]{}|;:',.<>/?\\-]).{8,}$/;
      const errors = [];

      if (!password) return;

      if (!regex.test(password)) {
        if (password.length < 8) {
          errors.push("La contraseña debe tener al menos 8 caracteres.");
        }
        if (!/[A-Z]/.test(password)) {
          errors.push(
            "La contraseña debe contener al menos una letra mayúscula."
          );
        }
        if (!/[!@#$%^&*()_+[\]{}|;:',.<>/?\\-]/.test(password)) {
          errors.push(
            "La contraseña debe contener al menos un carácter especial."
          );
        }
      }

      return errors.length > 0 ? errors.join(" ") : null;
    },

    confirmPasswordError() {
      const password2 = this.user.password2 ?? ""; // Asigna una cadena vacía si es null
      const password1 = this.user.password1 ?? ""; // Asegúrate de que password1 también sea una cadena

      if (!password2) return null;
      if (password2 !== password1) {
        return "Las contraseñas no coinciden";
      }
      return null;
    },
  },
  async created() {
    this.isLoading = !this.isLoading;
    this.setShowOverlay(this.isLoading);
    this.getPageTitle();

    // load data if edit or only read view
    // else do nothing
    this.$route.params.id ? await this.loadData() : this.initForm();

    await this.findCompany();
    await this.findGroup();

    this.isLoading = !this.isLoading;
    this.setShowOverlay(this.isLoading);
  },
  methods: {
    ...mapActions([
      "getEmpresaList",
      "getGroupList",
      "setShowOverlay",
      "getUserById",
      "saveUser",
    ]),
    initForm() {
      this.user = {
        username: null,
        email: null,
        first_name: null,
        last_name: null,
        password1: null,
        password2: null,
        empresa: null,
        groups: [],
      };
    },
    cargarMasEmpresas() {
      return this.empresas;
    },
    validateAllFields() {
      const errors = [];

      // Validar nombre
      if (!this.user.first_name) {
        errors.push("El nombre es requerido.");
      }

      // Validar apellido
      if (!this.user.last_name) {
        errors.push("El apellido es requerido.");
      }

      // Validar email
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!this.user.email || !emailRegex.test(this.user.email)) {
        errors.push("El email es inválido.");
      }

      // Validar username
      if (!this.user.username) {
        errors.push("El nombre de usuario es requerido.");
      }

      // Validar contraseña
      const passwordError = this.passwordError;
      if (passwordError) {
        errors.push(passwordError);
      }

      // Validar confirmación de contraseña
      const confirmPasswordError = this.confirmPasswordError;
      if (confirmPasswordError) {
        errors.push(confirmPasswordError);
      }

      // Validar empresa
      if (!this.user.empresa) {
        errors.push("La empresa es requerida.");
      }

      // Validar grupo
      if (!this.user.groups || this.user.groups.length === 0) {
        errors.push("El grupo es requerido.");
      }

      // Si hay errores, mostrar mensaje y retornar false
      if (errors.length > 0) {
        this.popupData = {
          show: true,
          type: "error",
          title: this.$t("popup.error.title"),
          message: errors.join(" "),
        };
        this.showPopup = true;
        return false;
      }

      return true;
    },
    async save() {
      if (!this.validateAllFields()) {
        return; // Si la validación falla, no continuar con el guardado
      }

      try {
        this.setShowOverlay(true);
        const res = await this.saveUser(this.user);
        const data = await res.json();
        this.setShowOverlay(false);

        const isOk = res.status >= 200 && res.status <= 300;

        this.popupData = {
          show: true,
          type: isOk ? "success" : "error",
          title: isOk
            ? this.$t("popup.success.title")
            : this.$t("popup.error.title"),
          message: isOk
            ? this.$t("popup.success.message")
            : data.non_field_errors[0],
        };
        this.showPopup = true;
      } catch (error) {
        this.setShowOverlay(false);
        this.popupData = {
          show: true,
          type: "error",
          title: this.$t("popup.error.title"),
          message: this.$t("popup.error.message"),
        };
        this.showPopup = true;
      }
    },
    async loadData() {
      try {
        this.user = await this.getUserById({ id: this.$route.params.id });
      } catch (error) {
        throw new Error(error);
      }
    },
    async findCompany(query) {
      const res = await this.getEmpresaList({ search: query, page: 1 });
      const { results } = await res.json();
      this.companyList = results;
    },
    async findGroup(query) {
      const res = await this.getGroupList({ search: query, page: 1 });
      const { results } = await res.json();

      this.groupList = results;
    },
    onTouchCompany() {
      this.isTouchedCompany = true;
    },
    onTouchGroup() {
      this.isTouchedGroup = true;
    },
  },
};
</script>
<style>
.invalid {
  color: red;
}
</style>
