import EncuestaService from "@/api/encuestas/encuesta"
import EncuestaCompletaService from "@/api/encuestas/encuestaCompleta"

const EncuestaStore = {
  state: {
    form: {
      anho: null,
      fechaCorte: null,
      secciones: [],
    },
    enuestaList: [],
  },
  mutations: {
    SET_ENCUESTA: (state, payload) => (state.form = payload),
    SET_ENCUESTA_LIST: (state, payload) => (state.encuestaList = payload),
  },
  actions: {
    async getEncuestaList({ dispatch }, { search, page, pageSize }) {
      const res = await EncuestaService.list(search, page, pageSize);

      switch (res.status) {
        case 401:
          dispatch("redirect", "/auth/login");
          break;
        case 404:
          dispatch("redirect", "/");
          break;
        default:
          return res;
      }
    },
    async getEncuestasCompletasList({ dispatch }, { search, page, pageSize }) {
      const res = await EncuestaCompletaService.list(search, page, pageSize);

      switch (res.status) {
        case 401:
          dispatch("redirect", "/auth/login");
          break;
        case 404:
          dispatch("redirect", "/");
          break;
        default:
          return res;
      }
    },
    setEncuesta({ commit }, payload) {
      commit("SET_ENCUESTA", payload);
    },
    setEncuestaList({ commit }, payload) {
      commit("SET_ENCUESTA_LIST", payload);
    },
    async deactivateEncuesta({ dispatch }, { id }) {
      const res = await EncuestaService.deactivate({ id });

      switch (res.status) {
        case 401:
          dispatch("redirect", "/auth/login");
          break;
        case 404:
          dispatch("redirect", "/");
          break;
        default:
          return res;
      }
    },
    async saveEncuesta({ state, dispatch }) {
      const res = state.form.id
        ? await EncuestaService.edit(state.form)
        : await EncuestaService.create(state.form);

      switch (res.status) {
        case 401:
          dispatch("redirect", "/auth/login");
          break;
        case 404:
          dispatch("redirect", "/");
          break;
        default:
          return res;
      }
    },
    async getEncuestaById({ dispatch }, { id }) {
      const res = await EncuestaService.getById({ id });
      let data = null;

      switch (res.status) {
        case 401:
          dispatch("redirect", "/auth/login");
          break;
        case 404:
          dispatch("redirect", "/");
          break;
        default:
          data = await res.json();
      }

      return data;
    },
    async getEncuestaUsuarioEmpresa({ dispatch }) {
      const res = await EncuestaService.getEncuestaUsuarioEmpresa();
      let data = null;

      switch (res.status) {
        case 401:
          dispatch("redirect", "/auth/login");
          break;
        case 404:
          dispatch("redirect", "/");
          break;
        default:
          data = await res.json();
      }

      return data;
    },
    async guardarEncuestaUsuarioEmpresa({ dispatch }, {encuesta, idEncuesta}) {
      const res = await EncuestaService.guardarEncuestaUsuarioEmpresa({encuesta, idEncuesta});

      switch (res.status) {
        case 401:
          dispatch("redirect", "/auth/login");
          break;
        case 404:
          dispatch("redirect", "/");
          break;
        default:
          return res;
      }
    },
    async getEncuestaCompletaById({ dispatch }, { id }) {
      const res = await EncuestaCompletaService.getById({ id });
      let data = null;

      switch (res.status) {
        case 401:
          dispatch("redirect", "/auth/login");
          break;
        case 404:
          dispatch("redirect", "/");
          break;
        default:
          data = await res.json();
      }

      return data;
    },
  },
  getters: {
    getEncuesta: (state) => state.form,
    getEncuestaList: (state) => state.encuestaList,
  },
};

export default EncuestaStore;
