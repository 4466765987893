import { createApp } from "vue";
import { configure, defineRule } from "vee-validate";
import * as rules from "@vee-validate/rules";
import { localize } from "@vee-validate/i18n";
import es from "@vee-validate/i18n/dist/locale/es.json";
import en from "@vee-validate/i18n/dist/locale/en.json";

// i18n
import i18n from '@/i18n';

// store
import store from '@/store';

// styles
import "@fortawesome/fontawesome-free/css/all.min.css";
import "@/assets/styles/tailwind.css";

// mouting point for the whole app

import App from "@/App.vue";

import router from '@/routers';

Object.keys(rules).forEach((rule) => {
  if (typeof rules[rule] === "function") {
    defineRule(rule, rules[rule]);
  }
});

configure({
  generateMessage: localize({
    es,
    en
  }),
  validateOnInput: true, // Esto es opcional y depende de cuándo quieras validar el campo
});

// Cambiar idioma basado en el store
store.watch(
  state => state.lang,
  lang => {
    localize(lang);
  }
);

createApp(App).use(router).use(i18n).use(store).mount("#app");
