<template>
  <crud-base
    :title="title"
    :popup-data="popupData"
    :show-popup="showPopup"
    @cancel="returnToList()"
    @add-register="save"
    @ok="returnToList"
  >
    <template v-slot:form>
      <div class="flex-auto lg:px-2 py-2 pt-4">
        <form>
          <div class="flex flex-wrap">
            <div class="w-full lg:w-6/12 px-2">
              <custom-input
                name="fecha"
                :label="$t('forms.periodo.fields.fechaInicio')"
                :disabled="isOnlyView"
                type="date"
                :placeholder="$t('forms.periodo.fieldsPlaceholder.fechaInicio')"
                v-model="periodo.fecha"
                validate="required"
              />
            </div>
            <div class="w-full lg:w-6/12 px-2">
              <custom-input
                name="fecha"
                :label="$t('forms.periodo.fields.fechaFin')"
                :disabled="isOnlyView"
                type="date"
                :placeholder="$t('forms.periodo.fieldsPlaceholder.fechaFin')"
                v-model="periodo.fecha_fin"
                validate="required"
              />
            </div>
          </div>
        </form>
      </div>
    </template>
  </crud-base>
</template>
<script>
import CrudBase from "@/components/Cards/CrudBase.vue";
import CustomInput from "@/components/Inputs/CustomInput.vue";
import { baseMixin } from "@/mixins/base";
import { mapActions } from "vuex";

export default {
  components: {
    CrudBase,
    CustomInput,
  },
  data() {
    return {
      module: "periodo",
      capitalizeModule: "Periodo",
      listName: "ListPeriodos",
      periodo: { fecha: null },
      showPopup: false,
      popupData: {},
    };
  },
  mixins: [baseMixin],
  async created() {
    this.isLoading = !this.isLoading;
    this.setShowOverlay(this.isLoading);
    this.getPageTitle();

    // load data if edit or only read view
    // else do nothing
    this.$route.params.id ? await this.loadData() : this.initForm();

    this.isLoading = !this.isLoading;
    this.setShowOverlay(this.isLoading);
  },
  methods: {
    ...mapActions([
      "setShowOverlay",
      "setPeriodo",
      "savePeriodo",
      "getPeriodoById",
    ]),
    initForm() {
      this.periodo = { fecha: null };
    },
    async save() {
      try {
        this.setShowOverlay(true);
        this.setPeriodo(this.periodo);

        const res = await this.savePeriodo();
        const data = await res.json();

        this.setShowOverlay(false);
        const isOk = res.status >= 200 && res.status <= 300;

        this.popupData = {
          show: true,
          type: isOk ? "success" : "error",
          title: isOk
            ? this.$t("popup.success.title")
            : this.$t("popup.error.title"),
          message: isOk
            ? this.$t("popup.success.message")
            : data.non_field_errors[0],
        };
        this.showPopup = true;
      } catch (error) {
        this.setShowOverlay(false);
        this.popupData = {
          show: true,
          type: "error",
          title: this.$t("popup.error.title"),
          message: this.$t("popup.error.message"),
        };
        this.showPopup = true;
      }
    },
    async loadData() {
      try {
        this.periodo = await this.getPeriodoById({
          id: this.$route.params.id,
        });
      } catch (error) {
        throw new Error(error);
      }
    },
  },
};
</script>
<style>
.invalid {
  color: red;
}
</style>
