<template>
  <div class="flex flex-col w-full">
    <div class="flex w-full px-2 justify-between">
      <div class="flex w-full">
        <label class="block uppercase text-blueGray-600 text-xs font-bold py-1 mr-2">
          {{ `Pregunta ${index + 1}` }}
        </label>
        <!-- Botón para subir la pregunta, se oculta si es la primera -->
        <button 
          v-if="!isOnlyView && index > 0"
          class="px-2 text-emerald-600" 
          @click.prevent="$emit('increase-order', index)"
          title="Subir Pregunta"
        >
          <i class="fas fa-arrow-up"></i>
        </button>
        <!-- Botón para bajar la pregunta, se oculta si es la última -->
        <button 
          v-if="!isOnlyView && index < totalPreguntas - 1"
          class="px-2 text-emerald-600" 
          @click.prevent="$emit('decrease-order', index)"
          title="Bajar Pregunta"
        >
          <i class="fas fa-arrow-down"></i>
        </button>
      </div>
      <div class="flex w-2">
        <button 
          v-if="!isOnlyView"
          class="px-2 text-emerald-600" 
          @click.prevent="$emit('edit-question', index)"
          title="Editar pregunta"
        >
          <i class="fas fa-pen"></i>
        </button>
        <button
          v-if="!isOnlyView"
          class="px-2"
          @click.prevent="$emit('remove-question', index)"
          title="Eliminar pregunta"
        >
          <i class="fas fa-trash-alt"></i>
        </button>
      </div>
    </div>
    <div class="flex w-full flex-col">
      <div class="flex w-full">
        <div class="w-full lg:w-6/12 px-2">
          <label for="" class="block uppercase text-blueGray-600 text-xs py-1 mr-2">
            {{ pregunta.descripcion }}
          </label>
        </div>
        <div class="flex lg:w-1 px-2 py-2 items-center">
          <label class="block uppercase text-blueGray-600 text-xs mr-2">{{ $t('forms.seccion.modalPreguntas.beneficio') }}</label>
          <input
            id="checkBeneficio"
            type="checkbox"
            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150"
            disabled
            :checked="pregunta.beneficio"
          />
        </div>
        <div class="flex lg:w-1 px-2 py-2 items-center">
          <label class="block uppercase text-blueGray-600 text-xs mr-2">{{ $t('forms.seccion.modalPreguntas.cerrada') }}</label>
          <input
            id="checkEsCerrada"
            type="checkbox"
            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150"
            disabled
            :checked="pregunta.cerrada"
          />
        </div>
        <div class="flex lg:w-1 px-2 py-2 items-center">
          <label class="block uppercase text-blueGray-600 text-xs mr-2">{{ $t('forms.seccion.modalPreguntas.multiple') }}</label>
          <input
            id="checkMultiple"
            type="checkbox"
            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150"
            disabled
            :checked="pregunta.multiple"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { baseMixin } from "@/mixins/base";
export default {
  props: {
    pregunta: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    totalPreguntas: {
      type: Number,
      required: true
    }
  },
  mixins: [baseMixin],
  watch: {
    pregunta: {
      deep: true,
      handle() { this.$forceUpdate() }
    }
  }
}
</script>
