import Base from "../base";
import Coockies from "js-cookie";

const COOKIE_NAME = "_riart_accessToken";

export default class UserServices extends Base {
  static endpointUrl = "usuarios/";

  static async getCurrentUserPermissionsByUser() {
    const token = Coockies.get(COOKIE_NAME);

    if (token) {
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const url = new URL(`${process.env.VUE_APP_API}usuario_permisos/`);

      return await fetch(url, { headers: headers });
    } else {
      throw new Error("No se encontró el token");
    }
  }

  static async adminChangeUserPassword(payload, lang) {
    const token = Coockies.get(COOKIE_NAME);
    const url = new URL(
      `${process.env.VUE_APP_API}admin_change_password/`
    );

    if (token) {
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      url.searchParams.append("lang", lang ?? "es");

      return await fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(payload),
      });
    } else {
      throw new Error("No se encontró el token");
    }
  }
}
