<template>
  <crud-base
    :title="title"
    :popup-data="popupData"
    :show-popup="showPopup"
    @cancel="returnToList()"
    @add-register="save"
    @ok="popupData.type === 'error' ? (showPopup = false) : returnToList()"
  >
    <template v-slot:form>
      <div class="flex-auto lg:px-2 py-2 pt-4">
        <form>
          <div class="flex flex-wrap">
            <div class="w-full lg:w-6/12 px-2">
              <custom-input
                name="razonSocial"
                :label="$t('forms.empresa.fields.razonSocial')"
                :disabled="isOnlyView"
                type="text"
                :placeholder="$t('forms.empresa.fieldsPlaceholder.razonSocial')"
                v-model="empresa.razon_social"
                :show-error-message="isValidRazonSocial"
                :error-message="errors?.razonSocial.join('')"
              />
            </div>
            <div class="w-full lg:w-6/12 px-2">
              <custom-input
                :label="$t('forms.empresa.fields.rucCi')"
                :disabled="isOnlyView"
                type="text"
                :placeholder="$t('forms.empresa.fieldsPlaceholder.rucCi')"
                v-model="empresa.ruc_ci"
                :show-error-message="isValidRuc"
                :error-message="errors?.ruc.join('')"
              />
            </div>
          </div>
          <div class="flex flex-wrap">
            <div class="w-full lg:w-6/12 px-2">
              <custom-input
                :label="$t('forms.empresa.fields.direccion')"
                :disabled="isOnlyView"
                type="text"
                :placeholder="$t('forms.empresa.fieldsPlaceholder.direccion')"
                v-model="empresa.direccion"
                :show-error-message="isValidDireccion"
                :error-message="errors?.direccion.join('')"
              />
            </div>
            <div
              class="w-full lg:w-6/12 px-2"
              :class="{
                'text-red-500 text-xs italic font-weight-bold mt-1':
                  !isValidRubro,
              }"
            >
              <label
                class="w-full block text-blueGray-600 text-xs font-bold mb-2"
              >
                {{ $t("forms.negocio.title") }}
              </label>
              <negocio-multiselect
                v-model="empresa.rubro"
                @update-rubro="onRubroChange"
              />
              <label class="typo__label form__label" v-if="!isValidRubro">{{
                errors?.rubro.join("")
              }}</label>
            </div>
          </div>
          <div class="flex flex-wrap">
            <div
              class="w-full lg:w-6/12 px-2"
              :class="{
                'text-red-500 text-xs italic font-weight-bold mt-1':
                  !isValidSegmento,
              }"
            >
              <label
                class="w-full block text-blueGray-600 text-xs font-bold mb-2"
              >
                {{ $t("forms.segmento.title") }}
              </label>
              <segmento-multiselect
                v-model="empresa.segmento"
                :rubro="empresa?.rubro"
              />
              <label class="typo__label form__label" v-if="!isValidSegmento">
                {{ errors?.rubro.join("") }}</label
              >
            </div>
            <div
              class="w-full lg:w-6/12 px-2"
              :class="{
                'text-red-500 text-xs italic font-weight-bold mt-1':
                  !isValidOrigen,
              }"
            >
              <label
                class="w-full block text-blueGray-600 text-xs font-bold mb-2"
              >
                {{ $t("forms.origen.title") }}
              </label>
              <origen-multiselect v-model="empresa.origen" />
              <label class="typo__label form__label" v-if="isValidOrigen">{{
                errors?.origen.join("")
              }}</label>
            </div>
          </div>
          <div class="flex flex-wrap mt-2">
            <div class="w-full lg:w-6/12 px-2">
              <custom-input
                min="0"
                type="number"
                validate="required"
                v-model="empresa.cant_sucursales"
                :label="$t('forms.empresa.fields.cantSucursales')"
                :disabled="isOnlyView"
                :placeholder="
                  $t('forms.empresa.fieldsPlaceholder.cantSucursales')
                "
                :show-error-message="isValidCantSucursales"
                :error-message="errors?.cantSucursales.join('')"
              />
            </div>
            <div class="w-full lg:w-6/12 px-2">
              <custom-input
                min="0"
                type="number"
                validate="required"
                v-model="empresa.cant_empleados"
                :label="$t('forms.empresa.fields.cantEmpleados')"
                :disabled="isOnlyView"
                :placeholder="
                  $t('forms.empresa.fieldsPlaceholder.cantEmpleados')
                "
                :show-error-message="isValidCantEmpleados"
                :error-message="errors?.cantEmpleados.join('')"
              />
            </div>
          </div>
        </form>
      </div>
    </template>
  </crud-base>
</template>
<script>
import CrudBase from "@/components/Cards/CrudBase.vue";
import CustomInput from "@/components/Inputs/CustomInput.vue";
import NegocioMultiselect from "@/views/negocios/NegocioMultiselect.vue";
import SegmentoMultiselect from "@/views/segmentos/SegmentoMultiselect.vue";
import OrigenMultiselect from "@/views/origenes/OrigenMultiselect.vue";
import { baseMixin } from "@/mixins/base";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    CrudBase,
    CustomInput,
    OrigenMultiselect,
    NegocioMultiselect,
    SegmentoMultiselect,
  },
  data() {
    return {
      module: "empresa",
      capitalizeModule: "Empresa",
      listName: "ListEmpresas",
      isTouchedNegocio: false,
      empresa: {
        rubro: null,
      },
      errors: {
        razonSocial: [],
        ruc: [],
        direccion: [],
        rubro: [],
        segmento: [],
        origen: [],
        idioma: [],
        cantSucursales: [],
        cantEmpleados: [],
      },
      showErrors: {
        razonSocial: false,
        ruc: false,
        direccion: false,
        rubro: false,
        segmento: false,
        origen: false,
        idioma: false,
        cantSucursales: false,
        cantEmpleados: false,
      },
    };
  },
  mixins: [baseMixin],
  async created() {
    try {
      this.isLoading = !this.isLoading;
      this.setShowOverlay(this.isLoading);
      this.getPageTitle();

      // load data if edit or only read view
      // else do nothing
      this.$route.params.id ? await this.loadData() : this.initForm();

      this.$forceUpdate();
      this.isLoading = !this.isLoading;
      this.setShowOverlay(this.isLoading);
    } catch (err) {
      this.isLoading = !this.isLoading;
      this.setShowOverlay(this.isLoading);
    }
  },
  computed: {
    ...mapGetters(["getLang"]),
    isValidRazonSocial() {
      return this.errors.razonSocial.length === 0;
    },
    isValidRuc() {
      return this.errors.ruc.length === 0;
    },
    isValidDireccion() {
      return this.errors.direccion.length === 0;
    },
    isValidRubro() {
      return this.errors.rubro.length === 0;
    },
    isValidSegmento() {
      return this.errors.segmento.length === 0;
    },
    isValidCantSucursales() {
      return this.errors.cantSucursales.length === 0;
    },
    isValidCantEmpleados() {
      return this.errors.cantEmpleados.length === 0;
    },
    isValidOrigen() {
      return this.errors.origen.length === 0;
    },
    isValidForm() {
      const isValid = {
        razonSocial: this.isValidRazonSocial,
        ruc: this.isValidRuc,
        direccion: this.isValidDireccion,
        rubro: this.isValidRubro,
        segmento: this.isValidSegmento,
        cantSucursales: this.isValidCantSucursales,
        cantEmpleados: this.isValidCantEmpleados,
        origen: this.isValidOrigen,
      };

      // verifica que todos los valores sean true
      return !Object.values(isValid).every((valor) => valor === true);
    },
  },
  methods: {
    ...mapActions([
      "getEmpresaById",
      "saveEmpresa",
      "setEmpresa",
      "setShowOverlay",
      "getNegocioList",
      "setNegocioList",
      "getSegmentoList",
      "setSegmentoList",
      "setShowOverlay",
    ]),
    initForm() {
      this.empresa = {
        razon_social: null,
        ruc_ci: null,
        direccion: null,
        rubro: null,
        segmento: null,
      };
    },
    async save() {
      try {
        if (this.isValidForm) {
          const errStr = Object.entries(this.errors)
            .filter(([, valor]) => valor.length > 0) 
            .map(([key, valor]) => `${key}:\n${valor}`) // Formatea la clave y valor
            .join("\n");

          Object.keys(this.showErrors).forEach((key) => {
            this.showErrors[key] = true;
          });

          this.setShowOverlay(false);
          this.popupData = {
            show: true,
            type: "error",
            title: this.$t("popup.error.title"),
            message: `El formulario contiene los siguientes errores: ${errStr}`,
          };
          this.showPopup = true;
          return;
        }

        this.setShowOverlay(true);
        this.setEmpresa(this.empresa);
        const res = await this.saveEmpresa();
        const data = await res.json();

        this.setShowOverlay(false);

        const isOk = res.status >= 200 && res.status <= 300;

        this.popupData = {
          show: true,
          type: isOk ? "success" : "error",
          title: isOk
            ? this.$t("popup.success.title")
            : this.$t("popup.error.title"),
          message: isOk
            ? this.$t("popup.success.message")
            : data.non_field_errors[0],
        };
        this.showPopup = true;
      } catch (error) {
        this.setShowOverlay(false);
        this.popupData = {
          show: true,
          type: "error",
          title: this.$t("popup.error.title"),
          message: error,
        };
        this.showPopup = true;
      }
    },
    async findNegocio(query) {
      try {
        const res = await this.getNegocioList({ search: query, page: 1 });
        const { results } = await res.json();
        this.setNegocioList(results);

        this.empresa = {
          ...this.empresa,
          rubro: results.filter((r) => r.id === query)[0],
        };
        this.$forceUpdate();
      } catch (err) {
        this.popupData = {
          type: "error",
          message: err,
        };
        this.showPopup = true;
      }
    },
    async findSegmento(query) {
      try {
        let res;
        res = await this.getSegmentoList({
          page: 1,
          rubroId: query,
        });

        if (res.ok) {
          // Verifica que la respuesta sea exitosa
          const { results } = await res.json();

          this.setSegmentoList(results);
          const segmento = results.filter((r) => r.id === query)[0];

          this.empresa = {
            ...this.empresa,
            segmento: segmento,
          };
        } else {
          this.setSegmentoList([]); // Limpia la lista si hay un error
        }
      } catch (err) {
        this.popupData = {
          type: "error",
          message: err,
        };
        this.showPopup = true;
      }
    },
    validateRazonSocial() {
      this.errors.razonSocial = [];

      if (!this.empresa?.razon_social) {
        this.errors.razonSocial.push("El campo es requerido");
        return false;
      }

      if (this.empresa.razon_social.length > 100) {
        this.errors.razonSocial.push(
          "La razón social no puede contener más de 100 caracteres"
        );
        return false;
      }

      return true;
    },
    validateRuc() {
      const regex = /^[0-9-]*$/;

      this.errors = {
        ...this.errors,
        ruc: [],
      };

      if (this.empresa?.ruc_ci && this.empresa?.ruc_ci.length === 0) {
        this.errors.ruc.push("El campo es requerido");
        return false;
      }

      if (this.empresa?.ruc_ci && this.empresa?.ruc_ci?.length > 30) {
        this.errors.ruc.push("La no puede contener más de 30 caracteres");
        return false;
      }

      if (this.empresa?.ruc_ci && !regex.test(this.empresa.ruc_ci)) {
        this.errors.ruc.push(
          "El campo puede contener solamente estos caracteres: [0-9] y el guión medio"
        );
        return false;
      }

      if (
        this.empresa?.ruc_ci &&
        this.empresa.ruc_ci?.charAt(this.empresa.ruc_ci.length - 1) === "-"
      ) {
        this.errors.ruc.push("El formato es incorrecto");
        return false;
      }

      return true;
    },
    validateDireccion() {
      this.errors = {
        ...this.errors,
        direccion: [],
      };

      if (this.empresa?.direccion && this.empresa.direccion.length === 0) {
        this.errors.direccion.push("El campo es requerido");
        return false;
      }

      if (this.empresa?.direccion && this.empresa?.direccion?.length > 100) {
        this.errors.direccion.push(
          "La dirección no puede contener más de 100 caracteres"
        );
        return false;
      }

      return true;
    },
    validateCantSucursales() {
      this.errors = {
        ...this.errors,
        cantSucursales: [],
      };

      if (
        this.empresa?.cant_sucursales &&
        this.empresa.cant_sucursales.length === 0
      ) {
        this.errors.cantSucursales.push("El campo es requerido");
        return false;
      }

      if (this.empresa?.cant_sucursales && this.empresa.cant_sucursales < 0) {
        this.errors.cantSucursales.push("El valor no puede ser menor a cero");
        return false;
      }

      return true;
    },
    validateCantEmpleados() {
      this.errors = {
        ...this.errors,
        cantEmpleados: [],
      };

      if (
        this.empresa?.cant_empleados &&
        this.empresa.cant_empleados.length === 0
      ) {
        this.errors.cantEmpleados.push("El campo es requerido");
        return false;
      }

      if (this.empresa?.cant_empleados && this.empresa.cant_empleados < 0) {
        this.errors.cantEmpleados.push("El valor no puede ser menor a cero");
        return false;
      }

      return true;
    },
    validateRubro() {
      this.errors = {
        ...this.errors,
        rubro: [],
      };

      if (!this.empresa.rubro) {
        this.errors.rubro.push("El campo es requerido");
        return false;
      }

      return true;
    },
    validateSegmento() {
      this.errors = {
        ...this.errors,
        segmento: [],
      };

      if (!this.empresa.segmento) {
        this.errors.segmento.push("El campo es requerido");
      }

      return true;
    },
    validateOrigen() {
      this.errors = {
        ...this.errors,
        origen: [],
      };

      if (!this.empresa.origen) {
        this.errors.origen.push("El campo es requerido");
        return false;
      }

      return true;
    },
    async loadData() {
      this.empresa = await this.getEmpresaById({ id: this.$route.params.id });
      await this.findNegocio(this.empresa.rubro);
      // await this.findSegmento(this.empresa.rubro?.id);
    },
    isInvalidOrigen() {
      return this.isTouchedOrigen;
    },
    onRubroChange(newRubro) {
      this.empresa.rubro = newRubro;
    },
  },
  watch: {
    "empresa.razon_social": {
      immediate: true,
      deep: true,
      handler() {
        this.validateRazonSocial();
      },
    },
    "empresa.ruc_ci": {
      immediate: true,
      deep: true,
      handler() {
        this.validateRuc();
      },
    },
    "empresa.direccion": {
      immediate: true,
      deep: true,
      handler() {
        this.validateDireccion();
      },
    },
    "empresa.cant_sucursales": {
      immediate: true,
      deep: true,
      handler() {
        this.validateCantSucursales();
      },
    },
    "empresa.cant_empleados": {
      immediate: true,
      deep: true,
      handler() {
        this.validateCantEmpleados();
      },
    },
    "empresa.rubro": {
      immediate: true,
      deep: true,
      handler() {
        this.validateRubro();
      },
    },
    "empresa.segmento": {
      immediate: true,
      deep: true,
      handler() {
        this.validateSegmento();
      },
    },
  },
};
</script>
