import PeriodoService from "@/api/encuestas/periodo";

const PeriodoStore = {
  state: {
    periodoList: [],
    form: {},
  },
  mutations: {
    SET_PERIODO_LIST: (state, periodoList) => (state.periodoList = periodoList),
    SET_PERIODO: (state, periodo) => (state.form = periodo),
  },
  actions: {
    async getPeriodoList({ dispatch, getters }, { search, page, pageSize }) {
      const lang = getters.getLang;
      const res = await PeriodoService.list(search, page, pageSize, lang);

      switch (res?.status) {
        case 401:
          dispatch("redirect", "/auth/login");
          break;
        case 404:
          dispatch("redirect", "/");
          break;
        default:
          return res;
      }
    },
    async savePeriodo({ state, dispatch }) {
      const res = state.form.id
        ? await PeriodoService.edit(state.form)
        : await PeriodoService.create(state.form);

      switch (res.status) {
        case 401:
          dispatch("redirect", "/auth/login");
          break;
        case 404:
          dispatch("redirect", "/");
          break;
        default:
          return res;
      }
    },
    async getPeriodoById({ commit, dispatch }, { id }) {
      const res = await PeriodoService.getById({ id });
      let data = null;

      switch (res.status) {
        case 401:
          dispatch("redirect", "/auth/login");
          break;
        case 404:
          dispatch("redirect", "/");
          break;
        default:
          data = await res.json();
          commit("SET_PERIODO", data);

          return data;
      }
    },
    setPeriodoList({ commit }, list) {
      commit("SET_PERIODO_LIST", list);
    },
    async deactivatePeriodo({ dispatch }, { id }) {
      const res = await PeriodoService.deactivate({ id });

      switch (res?.status) {
        case 401:
          dispatch("redirect", "/auth/login");
          break;
        case 404:
          dispatch("redirect", "/");
          break;
        default:
          return res;
      }
    },
    setPeriodo({ commit }, periodo) {
      commit("SET_PERIODO", periodo);
    }
  },
  getters: {
    getPeriodoListState: (state) => state.periodoList,
  },
};

export default PeriodoStore;
