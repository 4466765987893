<template>
  <div class="flex w-full">
    <multiselect
      v-model="seccion"
      :placeholder="$t('forms.seccion.fieldsPlaceholder.descripcion')"
      label="descripcion"
      :disabled="isOnlyView"
      :internal-search="false"
      :clear-on-select="false"
      :allow-empty="false"
      :options-limit="300"
      :options="getSeccionListState"
      @close="onTouch"
      @search-change="find"
      @select="handleSelected"
    >
    </multiselect>
    <div
      v-if="showBtn" 
      class="flex w-2"
    >
      <button
        v-if="!isOnlyView"
        class="px-2"
        @click.prevent="$emit('remove-seccion', index)"
      >
        <i class="fas fa-times"></i>
      </button>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import { baseMixin } from "@/mixins/base";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    Multiselect,
  },
  mixins: [baseMixin],
  data() {
    return {
      seccion: null,
      isTouched: false,
    };
  },
  props: {
    modelValue: {
      type: [String, Number, Object],
      required: true,
      default: null,
    },
    showBtn: {
      default: true,
      type: Boolean
    }
  },
  async created() {
    if(!this.isEditView || !this.isOnlyView) { await this.loadSecciones() }
  },
  watch: {
    modelValue: {
      immediate: true,
      handler() {
        this.find(null);
      },
    },
  },
  computed: {
    ...mapGetters(["getSeccionListState"]),
  },
  methods: {
    ...mapActions(["getSeccionList", "setSeccionList", "getSeccionById"]),
    async loadSecciones() {
      await this.find(null);
      this.setSeccion(this.modelValue);
    },
    async find(query) {
      try {
        const res = await this.getSeccionList({ search: query, page: 1 });
        const { results } = await res.json();
  
        this.setSeccionList(results.map(s => ({...s, descripcion: s.descripcion.toString().toUpperCase()})));
  
        if (this.modelValue && (this.isEditView || this.isOnlyView)) {
          const resSeccion = await this.getSeccionById({id: this.modelValue})
  
          if (resSeccion) {
            this.seccion = resSeccion
  
            if (!this.valueIsInList(this.seccion)) {
              const res1 = await this.getSeccionList({ search: this.seccion.descripcion, page: 1 });
              const { results } = await res1.json();
    
              this.setSeccionList(results.map(s => ({...s, descripcion: s.descripcion.toString().toUpperCase()})));
            }
          }
        }
      } catch (err) {
        console.error(err)
      }
    },
    valueIsInList(seccion) {
      const exists = this.getSeccionListState.filter(sec => sec.id === seccion.id)

      return exists.length > 0
    },
    setSeccion(value) {
      if (this.getSeccionListState.length > 0) {
        const selectedSeccion = this.getSeccionListState.find(
          (seccion) => seccion.id === value
        );
        if (selectedSeccion) {
          this.seccion = selectedSeccion;
          this.handleSelected(this.seccion);
        }
      }
    },
    onTouch() {
      this.isTouched = true;
    },
    handleSelected(value) {
      this.$emit("update:modelValue", value);
    },
  },
};
</script>
