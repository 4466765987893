<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import Cookies from 'js-cookie';

export default {
  created () {
    if (!Cookies.get('_riart_accessToken')) {
      this.$router.push('/auth/login');
    }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.esm.css" />
<style>
.multiselect__input {
  --tw-ring-shadow: none !important
}
</style>

