<template>
  <div>
    <li v-if="hasPermission(permissionName)" class="items-center">
      <router-link :to="routeName" v-slot="{ href, navigate, isActive }">
        <a
          :href="href"
          @click="navigate"
          class="text-xs uppercase py-2 font-bold block"
          :class="[
            isActive
              ? 'text-blueGray-500 hover:text-blueGray-500'
              : 'color-secondary-500 hover:color-secondary-500',
          ]"
        >
          <i
            class="fas fa-user-group mr-2 text-sm"
            :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"
          ></i>
          {{ title }}
        </a>
      </router-link>
    </li>
  </div>
</template>
<script>
import { mapActions } from 'vuex';

export default {
  props: {
    permissionName: {
      type: String,
      required: true,
    },
    routeName: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  methods: {
    ...mapActions(['hasCurrentUserPermission']),

    async hasPermission(code) {
      const hasPerm = await this.hasCurrentUserPermission(code);
      return hasPerm
    },
  },
};
</script>
