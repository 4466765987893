<template>
  <div class="relative flex flex-col min-w-0 break-words bg-white w-full border-solid border-red-200 border-radious rounded"
  >
    <div class="rounded-t mb-0 py-3 bg-transparent">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full max-w-full flex-grow flex-1">
          <h6 class="uppercase text-blueGray-400 mb-1 text-xs font-semibold">
            Permisos {{ title }}
          </h6>
        </div>
      </div>
    </div>
    <div class="p-4 flex-auto rounded rounded-md rounded-lg border-solid border-2 h-350-px overflow-scroll min-h-350">
      <div class="relative h-4/5">
        <ul>
          <li v-for="(permission, idx) in permissions" :key="permission.codename"
            :class="{ 'selected': permission.selected }"
            class="text-sm"
            @click="selectPermission(permission, idx)"
          >
            {{ `${permission.codename} | ${permission.name}` }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    selectPermission(permission) {
      permission.selected = !permission.selected;
      this.$emit('permission-click', permission)
    }
  },
  props: {
    permissions: {
      default: () => [],
      type: Array,
      required: true
    },
    title: {
      type: String,
      default: 'Disponibles',
      required: true
    }
  },
  emits: ['permission-click']
}
</script>
<style scoped>
  .selected {
    background-color: lightblue; /* Cambia el color de fondo cuando está seleccionado */
  }
</style>