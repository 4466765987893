export default class AuthAPI {
    static async login (userData) {
        return await fetch(`${process.env.VUE_APP_API}token/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(userData)
        })
    }

    static async sendEmailToRecoveryPassword ({email}) {
        return await fetch(`${process.env.VUE_APP_API}password-reset/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({email})
        })
    }

    static async resetPassword ({ password, verificationPassword, token }) {
        return await fetch(`${process.env.VUE_APP_API}send-new-password/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ 'password': password, 'confirm_password': verificationPassword, 'token': token })
        })
    }
}