<template>
  <div class="wizard w-full">
    <slot 
      :currentStep="currentStep" 
      :nextStep="nextStep"
      :previousStep="previousStep">
    </slot>
    <div class="flex justify-between w-full">
      <button 
        :class="currentStep === 1 ? 'text-blueGray-300': ''"
        @click="previousStep" :disabled="currentStep === 1">Anterior</button>
      <button 
        :class="currentStep === totalSteps ? 'text-blueGray-300': ''"
        @click="nextStep" :disabled="currentStep === totalSteps">Siguiente</button>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';

export default {
  name: 'Wizard',
  props: {
    totalSteps: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const currentStep = ref(1);

    const nextStep = () => {
      if (currentStep.value < props.totalSteps) {
        currentStep.value += 1;
      }
    };

    const previousStep = () => {
      if (currentStep.value > 1) {
        currentStep.value -= 1;
      }
    };

    return {
      currentStep,
      nextStep,
      previousStep,
    };
  },
};
</script>

<style scoped>
.wizard {
  display: flex;
  height: 70dvh;
  flex-direction: column;
  align-items: center;
}
button {
  margin: 5px;
}
</style>
