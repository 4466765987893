<template>
  <crud-base
    :title="title"
    :popup-data="popupData"
    :show-popup="showPopup"
    @cancel="returnToList()"
    @add-register="save"
    @ok="returnToList"
  >
    <template v-slot:form>
      <div class="flex-auto lg:px-2 py-2 pt-4">
        <form>
          <div class="flex flex-wrap">
            <div class="w-full lg:w-6/12 px-2">
              <custom-input
                name="descripcion"
                :label="$t('forms.negocio.fields.descripcion')"
                :disabled="isOnlyView"
                type="text"
                :placeholder="$t('forms.negocio.fieldsPlaceholder.descripcion')"
                v-model="negocio.descripcion"
                validate="required"
              />
            </div>
          </div>
          <div class="flex w-full flex-col">
            <div class="flex w-full px-2 mb-2">
              <span
                class="block uppercase text-blueGray-600 text-xs font-bold py-3 mr-2"
              >
                {{ $t("forms.segmento.title") }}
              </span>
              <button
                v-if="!isOnlyView"
                class="text-white shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none"
                type="button"
                :disabled="isOnlyView"
                :style="{ backgroundColor: 'cadetblue' }"
                @click.prevent="agregarSegmento"
              >
                <i class="fa fa-plus"></i>
              </button>
            </div>
            <div
              v-for="(item, idx) in negocio.segmentos"
              :key="idx"
              class="flex px-2 mb-2"
            >
              <input
                type="text"
                name="descripcion"
                :class="inputClass"
                :disabled="isOnlyView"
                :placeholder="
                  $t('forms.segmento.fieldsPlaceholder.descripcion')
                "
                v-model="item.descripcion"
              />
              <div v-if="!isOnlyView" class="flex w-2">
                <button
                  v-if="!isOnlyView"
                  class="px-2"
                  @click.prevent="handleRemoveSegmento(idx)"
                >
                  <i class="fas fa-times"></i>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </template>
  </crud-base>
</template>
<script>
import CrudBase from "@/components/Cards/CrudBase.vue";
import CustomInput from "@/components/Inputs/CustomInput.vue";
import { baseMixin } from "@/mixins/base";
import { mapActions } from "vuex";

export default {
  components: {
    CrudBase,
    CustomInput,
  },
  data() {
    return {
      module: "negocio",
      capitalizeModule: "Negocio",
      listName: "ListNegocios",
      negocio: { segmentos: [] },
    };
  },
  computed: {
    inputClass() {
      return [
        "border-0",
        "px-3",
        "py-3",
        "placeholder-blueGray-300",
        "text-blueGray-600",
        "bg-white",
        "rounded",
        "text-sm",
        "shadow",
        "focus:outline-none",
        "focus:ring",
        "w-full",
        "ease-linear",
        "transition-all",
        "duration-150",
        this.disabled ? "disabled" : "",
      ];
    },
  },
  mixins: [baseMixin],
  async created() {
    this.isLoading = !this.isLoading;
    this.setShowOverlay(this.isLoading);
    this.getPageTitle();

    // load data if edit or only read view
    // else do nothing
    this.$route.params.id ? await this.loadData() : this.initForm();

    this.isLoading = !this.isLoading;
    this.setShowOverlay(this.isLoading);
  },
  methods: {
    ...mapActions([
      "getNegocioById",
      "saveNegocio",
      "setShowOverlay",
      "setNegocio",
    ]),
    initForm() {
      this.negocio = {
        descripcion: null,
        segmentos: [],
      };
    },
    async save() {
      try {
        this.setShowOverlay(true);

        const form = {
          ...this.negocio,
        };
        this.setNegocio(form);
        const res = await this.saveNegocio();
        const data = await res.json();

        this.setShowOverlay(false);
        const isOk = res.status >= 200 && res.status <= 300;

        this.popupData = {
          show: true,
          type: isOk ? "success" : "error",
          title: isOk
            ? this.$t("popup.success.title")
            : this.$t("popup.error.title"),
          message: isOk
            ? this.$t("popup.success.message")
            : data.non_field_errors[0],
        };
        this.showPopup = true;
      } catch (error) {
        this.setShowOverlay(false);
        this.popupData = {
          show: true,
          type: "error",
          title: this.$t("popup.error.title"),
          message: this.$t("popup.error.message"),
        };
        this.showPopup = true;
      }
    },
    async loadData() {
      this.negocio = await this.getNegocioById({ id: this.$route.params.id });
    },
    agregarSegmento() {
      this.negocio.segmentos.push({ descripcion: null });
    },
    handleUpdateSegmento(index, value) {
      this.negocio.segmentos.splice(index, 1, value);
    },
    handleRemoveSegmento(index) {
      this.negocio.segmentos.splice(index, 1);
    },
  },
};
</script>
