import UserService from "@/api/users/users";

const UserStore = {
  state: {
    userList: [],
    form: {
      id: null,
      username: null,
      email: null,
      first_name: null,
      last_name: null,
      groups: null,
      password1: null,
      password2: null
    },
    currentUserPermissions: []
  },
  mutations: {
    setUserList: (state, userList) => (state.userList = userList),
    setUser: (state, user) => (state.form = user),
    setCurrentUserPermissions: (state, currentUserPermissions) => state.currentUserPermissions = currentUserPermissions
  },
  actions: {
    setUserList({ commit }, payload) {
      commit("setUserList", payload)
    },
    async getUserList({ dispatch }, { search, page, pageSize }) {
      const res = await UserService.list(search, page, pageSize);

      switch (res.status) {
        case 401:
          dispatch("redirect", "/auth/login");
          break;
        case 404:
          dispatch("redirect", "/");
          break;
        default:
          return res;
      }
    },
    async saveUser({ dispatch }, payload) {
      const user = {
        ...payload,
        groups: payload.groups ? [payload.groups.id] : [],
        permissions: payload.permissions ? payload.permissions.map(p => p.id) : [],
        empresa: payload.empresa?.id,
        idioma: payload.idioma?.id,
      }
      const res = payload.id ? await UserService.edit(user) : await UserService.create(user);

      switch (res.status) {
        case 401:
          dispatch("redirect", "/auth/login");
          break;
        case 404:
          dispatch("redirect", "/");
          break;
        default:
          return res;
      }
    },
    async getUserById({ dispatch }, { id }) {
      const res = await UserService.getById({ id });
      let data = null, empresa_id = null, groups_id = null;

      switch (res.status) {
        case 401:
          dispatch("redirect", "/auth/login");
          break;
        case 404:
          dispatch("redirect", "/");
          break;
        default:
          data = await res.json();

          empresa_id = await data.empresa
          groups_id = await data.groups[0]

          data.empresa = empresa_id ? await dispatch('getEmpresaById', { id: empresa_id }) : null
          data.groups = groups_id ? await dispatch('getUserGroupById', { id: groups_id }) : null

          return data;
      }
    },
    async getCurrentUserPermissionsByUser ({ commit, dispatch }) {
      const res = await UserService.getCurrentUserPermissionsByUser()
      const data = await res.json();

      switch (res.status) {
        case 401:
          dispatch("redirect", "/auth/login");
          break;
        case 404:
          dispatch("redirect", "/");
          break;
        default:
          commit("setCurrentUserPermissions", data)
      }
    },
    hasCurrentUserPermission({ dispatch, getters, state }, code) {
      if (getters.getCurrentUsrPermissionsState.lenght == 0) {
        dispatch('getCurrentUserPermissionsByUser')
      }
      const exist = state.currentUserPermissions.some(perm => perm.codename === code)
      return exist
    },
    async deactivateUser ({ dispatch }, { id }) {
      const res = await UserService.deactivate({ id });

      switch (res.status) {
        case 401:
          dispatch("redirect", "/auth/login");
          break;
        case 404:
          dispatch("redirect", "/");
          break;
        default:
          return res;
      }
    },
    async updateUserPassword ({ dispatch }, payload) {
      const res = await UserService.adminChangeUserPassword(payload);

      switch (res.status) {
        case 401:
          dispatch("redirect", "/auth/login");
          break;
        case 404:
          dispatch("redirect", "/");
          break;
        default:
          return res;
      }
    }
  },
  getters: {
    getCurrentUsrPermissionsState: async (state) => await state.currentUserPermissions
  },
};

export default UserStore;
