<template>
  <div class="flex items-center"
  >
    <popup
      v-if="showPopup"
      :popup="popupData"
      @close="handleOk"
    />
  <base-table
    :title="$t('forms.encuestasCompuestas.pluralTitle')"
    :records="records"
    @update:records="(val) => (records = val)"
    :table-columns="tableColumns"
    @update:table-columns="(val) => (tableColumns = val)"
    @add-row="addRow"
    @edit-row="editRow"
    @view-row="viewRow"
    @delete-row="deleteRow"
    @pageChanged="onChangePage"
    @search="searchInList"
    @reload-page="searchInList({ search: null })"
    :show-add-btn="false"
    :show-edit-btn="true"
    :show-delete-btn="false"
    :lang-dict="langDict"
  />
  </div>
</template>
<script>
import BaseTable from "@/components/Cards/BaseTable";
import Popup from "@/components/Cards/Popup.vue";
import { mapActions, mapGetters } from "vuex";
import { baseMixin } from "@/mixins/base";

export default {
  components: {
    BaseTable,
    Popup
  },
  data() {
    return {
      records: [],
      tableColumns: [],
      langDict: "forms.encuestasCompletas.tableColumns",
    };
  },
  async created() {
    await this.searchInList({ search: null });
  },
  computed: {
    ...mapGetters(["getPageSize", "getPage"]),
  },
  mixins: [baseMixin],
  methods: {
    ...mapActions([
      "getEncuestasCompletasList",
      "setEncuestaList",
      "setTableColumns",
      "setPage",
      "setEncuestaCompletaList",
      "deactivateEncuesta",
    ]),
    async addRow() {
      this.$router.push({ name: "AddEncuesta" });
    },
    async editRow(id) {
      this.$router.push({ name: "EditEncuestaCompleta", params: { id } });
    },
    async viewRow(id) {
      this.$router.push({ name: "ViewEncuestaCompleta", params: { id } });
    },
    async deleteRow(id) {
      const res = await this.deactivateEncuesta({ id });
      this.showPopupModal(res);
      this.showPopup = true;
    },
    async searchInList({ search }) {
      const res = await this.getEncuestasCompletasList({
        search: search,
        page: this.getPage,
        pageSize: this.getPageSize,
      });

      if (res.status === 200) {
        const { results, tableColumns } = await res.json();

        this.records = results;
        this.tableColumns = tableColumns;
        this.setEncuestaList(results);
        this.setTableColumns(tableColumns);
      }
    },
    async onChangePage(page) {
      this.setPage(page);
      await this.searchInList({ search: null });
    },
  },
};
</script>
