import TipoRespuestaService from "@/api/secciones/tipo_respuesta.js";

const TiposRespuestaStore = {
  state: {
    tiposRespuesta: [],
  },
  mutations: {
    SET_TIPO_RESPUESTA(state, tiposRespuesta) {
      state.tiposRespuesta = tiposRespuesta;
    },
  },
  actions: {
    async getTipoRespuestaList({ commit, dispatch }) {
      const res = await TipoRespuestaService.list();
      const { tipo_respuesta } = await res.json()

      switch (res.status) {
        case 401:
          dispatch("redirect", "/auth/login");
          break;
        case 404:
          dispatch("redirect", "/");
          break;
        default:
          commit('SET_TIPO_RESPUESTA', tipo_respuesta);
      }
    },
  },
  getters: {
    getTiposRespuesta: (state) => state.tiposRespuesta,
  },
};

export default TiposRespuestaStore;
