export const baseMixin = {
  data() {
    return {
      title: null,
      listName: "Home",
      isLoading: false,
      showPopup: false,
    };
  },
  computed: {
    isOnlyView() {
      return this.$route.fullPath.includes("view");
    },
    isEditView() {
      return this.$route.params.id && !this.isOnlyView;
    },
  },
  methods: {
    getPageTitle() {
      switch (this.$route.name) {
        case `Add${this.capitalizeModule}`:
          this.title = this.$t(`forms.${this.module}.addTitle`);
          break;
        case `Edit${this.capitalizeModule}`:
          this.title = this.$t(`forms.${this.module}.editTitle`);
          break;
        default:
          this.title = this.$t(`forms.${this.module}.title`);
          break;
      }
    },
    showPopupModal(res) {
      if (res.status >= 200 && res.status <= 300) {
        this.$store.state.popup = {
          show: true,
          type: "success",
          title: this.$t("popup.success.title"),
          message: this.$t("popup.success.message"),
        };
      } else {
        this.$store.state.popup = {
          show: true,
          type: "error",
          title: this.$t("popup.error.title"),
          message: this.$t("popup.error.message"),
        };
      }
    },
    handleOk() {
      this.showPopup = false;
      this.setShowOverlay(false);
    },
    returnToList() {
      this.$store.state.popup.show = false;
      this.showPopup = false;
      this.$router.push({ name: this.listName });
    },
  },
};
