<template>
  <div class="flex relative lg:w-6/12 max-w-xs">
    <input 
      v-model="search"
      @keyup.enter="emitSearch"
      placeholder="Search..."
      class="border-2 px-3 py-2 pl-10 rounded w-full"
      />
    <i class="fas fa-search absolute left-0 top-0 mt-3 ml-3 text-gray-500"></i>
  </div>
</template>
<script>
export default {
  data() {
    return {
      search: null
    }
  },
  methods: {
    emitSearch() {
      this.$emit('search', this.search)
    }
  }
}
</script>