<template>
  <div class="flex items-center">
    <popup v-if="showPopup" :popup="popupData" @close="handleOk" />
    <base-table
      :title="$t('forms.idiomas.pluralTitle')"
      :records="records"
      @update:records="(val) => (records = val)"
      :table-columns="tableColumns"
      @update:table-columns="(val) => (tableColumns = val)"
      @add-row="addRow"
      @edit-row="editRow"
      @view-row="viewRow"
      @delete-row="deleteRow"
      @pageChanged="onChangePage"
      @search="searchInList"
      @reload-page="searchInList({ search: null })"
      :lang-dict="langDict"
    />
  </div>
</template>
<script>
import BaseTable from "@/components/Cards/BaseTable";
import Popup from "@/components/Cards/Popup.vue";
import { mapActions, mapGetters } from "vuex";
import { baseMixin } from "@/mixins/base";

export default {
  components: {
    BaseTable,
    Popup
  },
  data() {
    return {
      records: [],
      tableColumns: [],
      langDict: "forms.idiomas.tableColumns",
    };
  },
  async created() {
    await this.searchInList({ search: null });
  },
  computed: {
    ...mapGetters(["getPageSize", "getPage"]),
  },
  mixins: [baseMixin],
  methods: {
    ...mapActions([
      "getIdiomaList",
      "setIdiomaList",
      "setTableColumns",
      "setPage",
      "setCount",
      "setShowOverlay",
      "deactivateIdioma"
    ]),
    async addRow() {
      this.$router.push({ name: "AddIdioma" });
    },
    async editRow(id) {
      this.$router.push({ name: "EditIdioma", params: { id } });
    },
    async viewRow(id) {
      this.$router.push({ name: "ViewIdioma", params: { id } });
    },
    async deleteRow(id) {
       try {
        const res = await this.deactivateIdioma({ id });

        if (res.status >= 200 && res.status < 300) {
          this.popupData = {
            type: "success",
            message: "La operación se completó con éxito",
          };
        }
        this.showPopup = true;
      } catch (err) {
        this.popupData = {
          type: "error",
          message: err,
        };
        this.showPopup = true;
      } finally {
        this.search = null;
        this.searchInList({ search: this.search });
        this.setShowOverlay(false);
      }
    },
    handleOk() {
      this.showPopup = false
      this.setShowOverlay(false)
    },
    async searchInList({ search }) {
      this.setShowOverlay(true);

      const res = await this.getIdiomaList({
        search: search,
        page: this.getPage,
        pageSize: this.getPageSize,
      });

      if (res.status === 200) {
        const { results, tableColumns, count } = await res.json();

        this.records = results;
        this.tableColumns = tableColumns;
        this.setCount(count);
        this.setIdiomaList(results);
        this.setTableColumns(tableColumns);
      }
      this.setShowOverlay(false);
    },
    async onChangePage(page) {
      this.setPage(page);
      await this.searchInList({ search: null });
    },
  },
};
</script>
