<template>
  <div>
    <overlay/>
    <sidebar />
    <div class="relative md:ml-64 bg-blueGray-100" style="height:100vh">
      <!-- <base-navbar /> -->
      <base-header />
      <div class="px-4 md:px-10 mx-auto w-full -m-24 h-full"
        :style="{ minHeight: 'auto', height: 'auto' }"
      >
        <router-view />
        <!-- <base-footer /> -->
      </div>
    </div>
  </div>
</template>
<script>
// import BaseNavbar from "@/components/Navbars/BaseNavbar.vue";
import Sidebar from "@/components/Sidebar/Sidebar.vue";
import BaseHeader from "@/components/Headers/BaseHeader.vue";
import Overlay from '@/components/Cards/Overlay.vue';
// import BaseFooter from "@/components/Footers/BaseFooter.vue";
export default {
  name: "base-layout",
  components: {
    // BaseNavbar,
    Sidebar,
    BaseHeader,
    Overlay
    // BaseFooter,
  },
};
</script>
