import GroupService from "@/api/users/groups";

const GroupStore = {
  state: {
    groupList: [],
    form: {
      name: null,
      permissions: [],
    },
    addedPermissions: [],
  },
  mutations: {
    SET_GROUP_LIST: (state, groupList) => (state.groupList = groupList),
    setUserGroup: (state, userGroup) => (state.form = userGroup),
    setAddedPermissions: (state, addedPermissions) => state.addedPermissions = addedPermissions
  },
  actions: {
    async getGroupList({ dispatch }, { search, page, pageSize }) {
      const res = await GroupService.list(search, page, pageSize);

      switch (res.status) {
        case 401:
          dispatch("redirect", "/auth/login");
          break;
        case 404:
          dispatch("redirect", "/");
          break;
        default:
          return res;
      }
    },
    async saveGroup({ state, dispatch }) {
      const group = { ...state.form, permissions: [] };

      state.addedPermissions.map((perm) =>
        group.permissions.push(perm.id)
      );

      const res = state.form.id ? await GroupService.edit(group) : await GroupService.create(group);

      switch (res.status) {
        case 401:
          dispatch("redirect", "/auth/login");
          break;
        case 404:
          dispatch("redirect", "/");
          break;
        default:
          return res;
      }
    },
    async getUserGroupById({ commit, dispatch }, { id }) {
      const res = await GroupService.getById({ id });
      let data = await res.json();

      switch (res.status) {
        case 401:
          dispatch("redirect", "/auth/login");
          break;
        case 404:
          dispatch("redirect", "/");
          break;
        default:
          commit("setUserGroup", data);

          return data;
      }
    },
    async addAllPermissions ({ commit, rootGetters }) {
      commit('setAddedPermissions', await rootGetters.getPermissionsListState)
      commit('setPermissionsList', [])
    },
    async deleteAllPermissions ({ commit, getters }) {
      commit('setPermissionsList', getters.getAddedPermissions)
      commit('setAddedPermissions', [])
    },
    async setAddedPermissions({ commit, rootGetters, state }) {
      const permissionsInForm = state.form.permissions;
      let permList = await rootGetters.getPermissionsListState;
      const added = permList.filter(perm => permissionsInForm.includes(perm.id));
      permList = await permList.filter(perm => !permissionsInForm.includes(perm.id));

      commit('setAddedPermissions', added);
      commit('setPermissionsList', permList);
    },
    setGroupList({ commit}, list) { commit('SET_GROUP_LIST', list) },
    async deactivateUserGroup ({ dispatch }, { id }) {
      const res = await GroupService.deactivate({ id });

      switch (res.status) {
        case 401:
          dispatch("redirect", "/auth/login");
          break;
        case 404:
          dispatch("redirect", "/");
          break;
        default:
          return res;
      }
    }
  },
  getters: {
    getAddedPermissions: (state) => state.addedPermissions,
    getGroupListState: (state) => state.groupList
  },
};

export default GroupStore;
