<template>
    <div class="container mx-auto px-4 h-full">
        <div class="flex content-center items-center justify-center h-full">
            <div class="w-full lg:w-4/12 px-4">
                <div
                    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0"
                    >
                    <div class="rounded-t mb-0 px-6 py-6">
                        <div class="text-center">
                            <h6 class="text-blueGray-500 text-sm font-bold">
                                {{ $t('message.login.resetPassword') }}
                            </h6>
                        </div>
                    </div>
                    <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
                      <form @submit.prevent="resetPassword">
                        <div class="relative w-full mb-3">
                          <label
                            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="password"
                          >
                            {{ $t('message.login.password') }}
                          </label>
                          <input
                            id="password"
                            type="password"
                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            :placeholder="$t('message.login.password')"
                            v-model="password"
                          />
                        </div>
                        <div class="relative w-full mb-3">
                          <label
                            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="verification-password"
                          >
                            {{ $t('message.login.confirmPassword') }}
                          </label>
                          <input
                            id="verification-password"
                            type="password"
                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            :placeholder="$t('message.login.confirmPassword')"
                            v-model="verificationPassword"
                          />
                        </div>
                        <div class="text-center mt-6">
                          <button
                            class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                            type="submit"
                          >
                          {{ $t('message.login.send') }}
                        </button>
                        </div>
                      </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex';

export default {
  data () {
    return {
      password: null,
      verificationPassword: null
    }
  },
  computed: {
    ...mapState(['email'])
  },
  methods: {
    resetPassword() {
      this.$store.dispatch('resetPassword', 
      {"password":this.password,
        "verificationPassword": this.verificationPassword,
        "token": this.$route.params.token
      })
    }
  }
}
</script>