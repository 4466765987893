<template>
  <div>
    <multiselect
      v-model="origen"
      :placeholder="$t('forms.origen.fieldsPlaceholder.descripcion')"
      label="descripcion"
      :disabled="isOnlyView"
      :internal-search="false"
      :clear-on-select="false"
      :allow-empty="false"
      :options-limit="300"
      :options="getOrigenListState"
      @close="onTouch"
      @search-change="find"
      @select="handleSelected"
    >
    </multiselect>
  </div>
</template>
<script>
import Multiselect from "vue-multiselect";
import { baseMixin } from "@/mixins/base";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    Multiselect,
  },
  mixins: [baseMixin],
  data() {
    return {
      origen: null,
      isTouched: false,
    };
  },
  props: {
    modelValue: {
      type: [String, Number, Object],
      required: true,
    },
  },
  async created() {
    await this.find(null);
    this.origen = this.getOrigenListState.find((o) => o.id === this.modelValue);
    this.handleSelected(this.origen);
  },
  computed: {
    ...mapGetters(["getOrigenListState"]),
  },
  methods: {
    ...mapActions(["getOrigenList", "setOrigenList"]),
    async find(query) {
      const res = await this.getOrigenList({ search: query, page: 1 });
      const { results } = await res.json();
      this.setOrigenList(results);
    },
    onTouch() {
      this.isTouched = true;
    },
    handleSelected(value) {
      this.$emit("update:modelValue", value);
    },
  },
};
</script>
