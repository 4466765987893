<template>
  <div class="relative flex justify-center h-full items-center">
    <popup
      v-if="showPopup"
      :popup="popupData"
      @close="handleOk"
    />
    <div class="w-full lg:w-12/12 px-1">
      <div
        class="flex flex-col min-w-0 break-words w-full shadow-lg rounded-lg bg-blueGray-100 border-0"
      >
        <div class="rounded-t mb-0 px-6 py-6">
          <div class="text-center flex justify-between">
            <h6 class="text-blueGray-700 text-xl font-bold">
              {{ title }}
            </h6>
          </div>
        </div>
        <div class="flex-auto px-4 lg:px-8 bg-white h-full">
          <slot name="form"></slot>
        </div>
        <!-- footer -->
        <div class="flex flex-auto justify-end px-4 lg:px- py-4">
          <button
            class="bg-slate-400 text-blueGray-500 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
            type="button"
            @click.prevent.stop="$emit('cancel')"
          >
            {{ $t('btnLabels.cancel') }}
          </button>
          <button
            v-if="!isOnlyView"
            class="ml-1 text-white bg-secondary font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
            type="button"
            @click.prevent.stop="$emit('add-register')"
          >
            {{ $t(`${getButtonLabel()}`) }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Popup from "@/components/Cards/Popup.vue"; // Asegúrate de que este camino sea correcto
import { baseMixin } from '@/mixins/base';

export default {
  name: 'CrudBase',
  components: {
    Popup
  },
  props: {
    title: {
      type: String,
      required: true
    },
    defaultHeight: {
      type: String,
      default: '70vh'
    },
    popupData: {
      type: Object,
      required: true
    },
    showPopup: {
      type: Boolean,
      default: false
    }
  },
  mixins: [baseMixin],
  methods: {
    getButtonLabel() {
      if (this.$route.params.id) {
        return this.isOnlyView ? 'btnLabels.view' : 'btnLabels.save'
      } else {
        return 'btnLabels.add'
      }
    },
    handleOk() {
      this.$emit('ok')
    },
  },
  emits: ['add-register', 'cancel', 'ok']
}
</script>
