<template>
  <div>
    <multiselect
      v-if="listaPreguntas.length > 0"
      v-model="pregunta"
      :placeholder="$t('forms.seccion.modalPreguntas.preguntaPadre')"
      label="descripcion"
      key="id"
      :disabled="isOnlyView"
      :internal-search="false"
      :options-limit="300"
      :options="listaPreguntas"
      @close="onTouch"
      @select="handleSelected"
    >
    </multiselect>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import { baseMixin } from "@/mixins/base";

export default {
  components: {
    Multiselect,
  },
  mixins: [baseMixin],
  data() {
    return {
      pregunta: this.modelValue,
      isTouched: false,
      internalValue: this.modelValue,
      listaPreguntas: {...this.options}
    };
  },
  props: {
    modelValue: {
      type: [String, Number, Object, null],
      required: true,
      default: null,
    },
    options: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    modelValue: {
      immediate: true,
      handler() {
        this.internalValue = this.pregunta;
      },
    },
    internalValue(val) {
      this.$emit("update:modelValue", val);
    },
    options: {
      immediate: true,
      handler(newOptions) {
        if (newOptions.length > 0) {
          this.listaPreguntas = newOptions;
        }
      },
    },
  },
  methods: {
    onTouch() {
      this.isTouched = true;
    },
    handleSelected(value) {
      this.$emit("update:modelValue", value);
    },
  },
};
</script>
<style scoped>
.font-weight-bold {
  font-weight: bold;
}
.text-red-500 {
  color: #ef4444; /* Mantener el color rojo */
}
.disabled {
  background-color: rgb(241 245 249) !important;
}
</style>
