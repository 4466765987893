import Coockies from 'js-cookie';
import Base from "../base";


export default class GroupsServices extends Base {
    static endpointUrl = "grupos/"

    static async getUserGroup (userId) {
        const token = Coockies.get('_riart_accessToken');
        const url = new URL(`${process.env.VUE_APP_API}${this.endpointUrl}`)

        if (token) {
            const headers = {'Authorization': `Bearer ${token}`};
            url.searchParams.append('userId', userId);
    
            return await fetch(url, {headers: headers})
        } else {
            throw new Error("No se encontró el token")
        }
    }

    static async getPermissionsByGroup (groupName) {
        const token = Coockies.get('_riart_accessToken');
        const url = new URL(`${process.env.VUE_APP_API}${this.endpointUrl}`)

        if (token) {
            const headers = {
                'Authorization': `Bearer ${token}`
            };
            url.searchParams.append('search', groupName);
    
            return await fetch(url, {headers: headers})
        } else {
            throw new Error("No se encontró el token")
        }
    }
}