<template>
  <crud-base
    :title="$t('forms.seccion.title')"
    default-heigth="auto"
    :popup-data="popupData"
    :show-popup="showPopup"
    @cancel="
      popupData.type === 'info' ? (this.showPopup = false) : returnToList()
    "
    @add-register="save"
    @ok="
      popupData.type === 'info' || popupData.type === 'error'
        ? (this.showPopup = false)
        : returnToList()
    "
  >
    <template v-slot:form>
      <div class="flex-auto lg:px-2 py-2 pt-4">
        <div>
          <div class="flex flex-wrap">
            <div class="w-full lg:w-6/12 px-2">
              <custom-input
                :label="$t('forms.seccion.descripcion')"
                :placeholder="$t('forms.seccion.fieldsPlaceholder.descripcion')"
                :disabled="isOnlyView"
                type="text"
                v-model="seccion.descripcion"
              />
            </div>
            <div class="w-full lg:w-6/12 px-2">
              <label
                class="w-full block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                {{ $t("forms.idiomas.title") }}
              </label>
              <idiomas-multiselect v-model="seccion.idioma" />
            </div>
          </div>
          <div class="flex flex-wrap mt-4">
            <div class="flex w-full px-2 mb-2 justify-between">
              <div class="flex">
                <span
                  class="block uppercase text-blueGray-600 text-xs font-bold py-3 mr-2"
                >
                  {{ $t("forms.seccion.preguntas") }}
                </span>
                <button
                  v-if="!isOnlyView"
                  class="bg-secondary text-white shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none"
                  type="button"
                  @click.prevent="addQuestion"
                  title="Agregar pregunta"
                >
                  <i class="fa fa-plus"></i>
                </button>
              </div>
              <searcher @search="handleSearch" />
            </div>
            <div
              class="flex flex-col w-full px-2 py-2 overflow-scroll"
              :style="{ height: '40vh' }"
            >
              <lista-preguntas
                class="question"
                v-for="(item, idx) in orderedPreguntas"
                :key="`pregunta_${item.orden}`"
                :pregunta="item"
                :index="idx"
                :total-preguntas="seccion.preguntas.length"
                @remove-question="handleRemoveQuestion"
                @edit-question="handleEditQuestion(idx)"
                @increase-order="increaseOrder(idx)"
                @decrease-order="decreaseOrder(idx)"
              />
            </div>
          </div>
        </div>
        <modal-pregunta
          :show-modal-pregunta="showModalPregunta"
          :is-edit.sync="isEdit"
          :pregunta-actual="modalForm"
          :preguntas="seccion?.preguntas ?? []"
          @close-modal="showModalPregunta = false"
          @update-question="handleUpdateQuestion"
          @commit-question="handleCommitQuestion"
        />
      </div>
    </template>
  </crud-base>
</template>
<script>
import CrudBase from "@/components/Cards/CrudBase";
import CustomInput from "@/components/Inputs/CustomInput.vue";
import ListaPreguntas from "@/components/Encuestas/ListaPreguntas";
import ModalPregunta from "@/components/Encuestas/ModalPregunta.vue";
import IdiomasMultiselect from "@/views/idiomas/IdiomasMultiselect";
import Searcher from "@/components/Inputs/Searcher.vue";
import { baseMixin } from "@/mixins/base";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    CrudBase,
    CustomInput,
    ListaPreguntas,
    ModalPregunta,
    IdiomasMultiselect,
    Searcher,
  },
  mixins: [baseMixin],
  data() {
    return {
      module: "seccion",
      capitalizeModule: "Seccion",
      listName: "ListSecciones",
      seccion: {
        preguntas: [],
      },
      searchQuery: "",
      showModalPregunta: false,
      isEdit: false,
      toUpdateIndex: -1,
      preguntasOriginales: [],
      modalForm: {},
    };
  },
  computed: {
    ...mapGetters(["getQuestionModalForm", "getTiposRespuesta"]),
    orderedPreguntas() {
      return [...this.seccion.preguntas].sort((a, b) => a.orden - b.orden);
    },
  },
  async created() {
    this.isLoading = !this.isLoading;
    this.setShowOverlay(this.isLoading);
    this.getPageTitle();

    this.$route.params.id ? await this.loadData() : this.initForm();

    this.isLoading = !this.isLoading;
    this.setShowOverlay(this.isLoading);
  },
  methods: {
    ...mapActions([
      "setModalForm",
      "saveSeccion",
      "setSeccion",
      "getSeccionById",
      "getTipoRespuestaList",
      "setShowOverlay",
    ]),
    initForm() {
      this.seccion = {
        descripcion: null,
        idioma: null,
        preguntas: [],
      };
    },
    async loadData() {
      await this.getTipoRespuestaList();
      this.seccion = await this.getSeccionById({ id: this.$route.params.id });
      const tiposRespuestas = this.getTiposRespuesta;

      this.seccion = {
        ...this.seccion,
        preguntas: this.seccion.preguntas.map((p) => {
          return {
            ...p,
            tipo_respuesta: tiposRespuestas.find(
              (tr) => tr.id === p.tipo_respuesta
            ),
            padre: this.seccion.preguntas.find((pr) => pr.id === p.padre),
          };
        }),
      };
      this.preguntasOriginales = this.seccion.preguntas;
      this.$forceUpdate();
    },
    increaseOrder(index) {
      if (index > 0) {
        const temp = this.seccion.preguntas[index];
        this.seccion.preguntas[index] = this.seccion.preguntas[index - 1];
        this.seccion.preguntas[index - 1] = temp;
        this.actualizarOrden();
      }
    },
    decreaseOrder(index) {
      if (index < this.seccion.preguntas.length - 1) {
        const temp = this.seccion.preguntas[index];
        this.seccion.preguntas[index] = this.seccion.preguntas[index + 1];
        this.seccion.preguntas[index + 1] = temp;
        this.actualizarOrden();
      }
    },
    actualizarOrden() {
      this.seccion.preguntas.forEach((pregunta, index) => {
        pregunta.orden = index; // Actualiza el atributo de orden
      });
    },
    async addQuestion() {
      // this.handleSearch();
      this.isEdit = false;
      this.showModalPregunta = true;
      this.modalForm = this.setInitModalForm();
      this.$forceUpdate();
    },
    setInitModalForm() {
      return {
        descripcion: null,
        cerrada: false,
        multiple: false,
        beneficio: false,
        respuestas: [],
      };
    },
    handleSearch(query) {
      this.searchQuery = query;

      if (!query || query.trim() === "") {
        this.seccion.preguntas = [...this.preguntasOriginales];
      } else {
        this.seccion.preguntas = this.preguntasOriginales.filter((pregunta) =>
          pregunta.descripcion.toLowerCase().includes(query.toLowerCase())
        );
      }
      this.showModalPregunta = false;
    },
    handleRemoveQuestion(index) {
      this.seccion.preguntas.splice(index, 1);
    },
    async handleCommitQuestion(newQuestion) {
      const maxOrden = Math.max(
        0,
        ...this.seccion.preguntas.map((pregunta) => pregunta.orden || 0)
      );

      this.seccion.preguntas.push({
        ...newQuestion,
        orden: maxOrden + 1,
      });
      this.preguntasOriginales = [...this.seccion.preguntas];
      this.$forceUpdate();
    },
    handleEditQuestion(idx) {
      this.modalForm = { ...this.seccion?.preguntas[idx] };
      this.toUpdateIndex = idx;
      this.isEdit = true;
      this.showModalPregunta = true;
    },
    handleUpdateQuestion(updatedQuestion) {
      this.seccion.preguntas.splice(this.toUpdateIndex, 1, {
        ...updatedQuestion,
      });
      this.$forceUpdate();
    },
    async save() {
      try {
        this.setShowOverlay(true);
        this.seccion = {
          ...this.seccion,
          idioma: this.seccion?.idioma?.id,
          preguntas: this.seccion.preguntas.map((pregunta) => {
            return {
              ...pregunta,
              orden: pregunta?.orden,
              tipo_respuesta: pregunta.tipo_respuesta?.id,
              padre: pregunta.padre?.id,
            };
          }),
        };

        this.setSeccion(this.seccion);
        const res = await this.saveSeccion();
        const data = await res.json();

        this.setShowOverlay(false);
        const isOk = res.status >= 200 && res.status <= 300;

        this.popupData = {
          show: true,
          type: isOk ? "success" : "error",
          title: isOk
            ? this.$t("popup.success.title")
            : this.$t("popup.error.title"),
          message: isOk
            ? this.$t("popup.success.message")
            : data.non_field_errors[0],
        };
        this.showPopup = true;
      } catch (error) {
        this.setShowOverlay(false);
        this.popupData = {
          show: true,
          type: "error",
          title: this.$t("popup.error.title"),
          message: this.$t("popup.error.message"),
        };
        this.showPopup = true;
      }
    },
  },
};
</script>
<style scope>
.question {
  border-radius: 0; /* Asegura que todos los elementos tengan esquinas rectas por defecto */
  border: 1px solid #e2e2e2;
  border-bottom: 0px;
}

.question:first-child {
  border-top-left-radius: 10px; /* Redondea las esquinas superiores */
  border-top-right-radius: 10px;
  border-bottom-left-radius: 0; /* Mantiene las esquinas inferiores rectas */
  border-bottom-right-radius: 0;
  border-bottom: 0px;
}

.question:last-child {
  border-bottom-left-radius: 8px; /* Redondea las esquinas inferiores del último elemento */
  border-bottom-right-radius: 8px;
  border-bottom: 1px solid #e2e2e2;
  margin-bottom: 2rem;
}
</style>
