<template>
  <div>
    <multiselect
      v-model="periodoActivo"
      :placeholder="$t('forms.periodoActivo.fieldsPlaceholder.multiselect')"
      :custom-label="getPeriodoActivoLabel"
      :disabled="isOnlyView"
      :internal-search="false"
      :clear-on-select="false"
      :allow-empty="false"
      :options-limit="300"
      :options="getPeriodoActivoListState"
      @close="onTouch"
      @search-change="find"
      @select="handleSelected"
    >
    </multiselect>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import { baseMixin } from "@/mixins/base";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    Multiselect,
  },
  mixins: [baseMixin],
  data() {
    return {
      periodoActivo: null,
      isTouched: false,
    };
  },
  props: {
    modelValue: {
      type: [String, Number, Object],
      required: true,
      default: null,
    },
  },
  async created() {
    await this.loadPeriodoActivo();
  },
  computed: {
    ...mapGetters(["getPeriodoActivoListState"]),
  },
  methods: {
    ...mapActions(["getPeriodoActivoList", "setPeriodoActivoList"]),
    async loadPeriodoActivo() {
      await this.find(null);
      this.setPeriodoActivo(this.modelValue);
    },
    async find(query) {
      const res = await this.getPeriodoActivoList({ search: query, page: 1 });
      const { results } = await res.json();
      this.setPeriodoActivoList(results);
    },
    setPeriodoActivo(value) {
      if (this.getPeriodoActivoListState.length > 0) {
        const selectedPeriodoActivo = this.getPeriodoActivoListState.find(periodoActivo => periodoActivo.id === value);
        if (selectedPeriodoActivo) {
          this.periodoActivo = selectedPeriodoActivo;
          this.handleSelected(this.periodoActivo);
        }
      }
    },
    onTouch() {
      this.isTouched = true;
    },
    handleSelected(value) {
      this.$emit("update:modelValue", value);
    },
    getPeriodoActivoLabel (val) {
      return `${val.periodo}: ${val.rubro}`
    }
  },
  watch: {
    modelValue: {
      immediate: true,
      handler(newValue) {
        this.setPeriodoActivo(newValue);
      },
    },
    getPeriodoActivoListState: {
      immediate: true,
      handler() {
        this.setPeriodoActivo(this.modelValue);
      },
    },
  },
};
</script>
