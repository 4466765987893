import SegmentoService from "@/api/segmentos/segmentos";

const SegmentoStore = {
  state: {
    segmentoList: [],
    form: {
      descripcion: null,
    },
  },
  mutations: {
    SET_SEGMENTO_LIST: (state, segmentoList) => (state.segmentoList = segmentoList),
    SET_SEGMENTO: (state, segmento) => (state.form = segmento),
  },
  actions: {
    async getSegmentoList({ dispatch, getters }, { search, page, pageSize, rubroId }) {
      const lang = getters.getLang;

      const res = rubroId
        ? await SegmentoService.list(search, page, pageSize, lang, rubroId)
        : await SegmentoService.list(search, page, pageSize, lang);

      switch (res.status) {
        case 401:
          dispatch("redirect", "/auth/login");
          break;
        case 404:
          dispatch("redirect", "/");
          break;
        default:
          return res;
      }
    },
    async saveSegmento({ state, dispatch }) {
      const res = state.form.id ? await SegmentoService.edit(state.form) : await SegmentoService.create(state.form);

      switch (res.status) {
        case 401:
          dispatch("redirect", "/auth/login");
          break;
        case 404:
          dispatch("redirect", "/");
          break;
        default:
          return res;
      }
    },
    async getSegmentoById({ commit, dispatch, getters }, { id }) {
      const lang = getters.getLang;
      const res = await SegmentoService.getById({ id }, lang);
      let data = null;

      switch (res.status) {
        case 401:
          dispatch("redirect", "/auth/login");
          break;
        case 404:
          dispatch("redirect", "/");
          break;
        default:
          data = await res.json();
          commit("SET_SEGMENTO", data);

          return data;
      }
    },
    setSegmentoList({ commit }, list) { commit('SET_SEGMENTO_LIST', list) },
    async deactivateSegmento ({ dispatch }, { id }) {
      const res = await SegmentoService.deactivate({ id });

      switch (res.status) {
        case 401:
          dispatch("redirect", "/auth/login");
          break;
        case 404:
          dispatch("redirect", "/");
          break;
        default:
          return res;
      }
    }
  },
  getters: {
    getSegmentoListState: (state) => state.segmentoList
  },
};

export default SegmentoStore;
