<template>
  <div>
      <multiselect
        v-model="idioma"
        :placeholder="$t('forms.idiomas.multiselect')"
        label="descripcion"
        :disabled="isOnlyView"
        :internal-search="false"
        :clear-on-select="false"
        :allow-empty="false"
        :options-limit="300"
        :options="getIdiomaListState"
        @close="onTouch"
        @search-change="find"
        @select="handleSelected"
      >
      </multiselect>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import { baseMixin } from "@/mixins/base";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    Multiselect,
  },
  mixins: [baseMixin],
  data() {
    return {
      idioma: this.modelValue,
      isTouched: false,
      internalValue: this.modelValue,
    };
  },
  props: {
    modelValue: {
      type: [String, Number, Object, null],
      required: true,
      default: null,
    },
    validation: {
      type: String,
      default: ''
    }
  },
  async created() {
    await this.loadIdiomas();
  },
  watch: {
    modelValue: {
      immediate: true,
      handler(newValue) {
        this.setIdioma(newValue);
        this.internalValue = this.idioma;
      },
    },
    getIdiomaListState: {
      immediate: true,
      handler() {
        this.setIdioma(this.modelValue);
      },
    },
    internalValue(val) {
      this.$emit('update:modelValue', val);
    },
  },
  computed: {
    ...mapGetters(["getIdiomaListState", "getLang"]),
    fieldName() {
      return this.getLang === 'es' ? 'idioma': 'language'
    }
  },
  methods: {
    ...mapActions(["getIdiomaList", "setIdiomaList"]),
    async loadIdiomas() {
      await this.find(null);
      this.setIdioma(this.modelValue);
    },
    async find(query) {
      const res = await this.getIdiomaList({ search: query, page: 1 });
      const { results } = await res.json();
      this.setIdiomaList(results);
    },
    setIdioma(value) {
      if (this.getIdiomaListState.length > 0) {
        const selectedIdioma = this.getIdiomaListState.find(idioma => idioma.id === value);
        if (selectedIdioma) {
          this.idioma = selectedIdioma;
          this.handleSelected(this.idioma);
        }
      }
    },
    onTouch() {
      this.isTouched = true;
    },
    handleSelected(value) {
      this.$emit("update:modelValue", value);
    },
  },
};
</script>
<style scoped>
.font-weight-bold {
  font-weight: bold;
}
.text-red-500 {
  color: #EF4444; /* Mantener el color rojo */
}
.disabled {
  background-color: rgb(241 245 249) !important;
}
</style>
