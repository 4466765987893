import Coockies from "js-cookie";
import { PAGE_SIZE } from "@/utils/constants";
const COOKIE_NAME = "_riart_accessToken";

export default class Base {
  constructor(endpointUrl) {
    this.endpointUrl = endpointUrl;
  }

  static async create(object, lang) {
    const token = Coockies.get(COOKIE_NAME);
    const url = new URL(`${process.env.VUE_APP_API}${this.endpointUrl}`);

    if (token) {
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      url.searchParams.append("lang", lang ?? "es");

      return await fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(object),
      });
    } else {
      throw new Error("No se encontró el token");
    }
  }

  static async edit(object, lang) {
    const token = Coockies.get(COOKIE_NAME);
    const url = new URL(
      `${process.env.VUE_APP_API}${this.endpointUrl}${object.id}/`
    );

    if (token) {
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      url.searchParams.append("lang", lang ?? "es");

      return await fetch(url, {
        method: "PATCH",
        headers: headers,
        body: JSON.stringify(object),
      });
    } else {
      throw new Error("No se encontró el token");
    }
  }

  static async list(search, page, pageSize, lang) {
    const token = Coockies.get(COOKIE_NAME);
    const url = new URL(`${process.env.VUE_APP_API}${this.endpointUrl}`);

    if (token) {
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      search ? url.searchParams.append("search", search) : null;
      url.searchParams.append("lang", lang ?? "es");
      url.searchParams.append("page", page ?? 1);
      url.searchParams.append("page_size", pageSize ?? PAGE_SIZE);

      return await fetch(url, {
        headers: headers,
      });
    } else {
      throw new Error("No se encontró el token");
    }
  }

  static async getById({ id }, lang) {
    const token = Coockies.get(COOKIE_NAME);

    if (token) {
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const url = new URL(
        `${process.env.VUE_APP_API}${this.endpointUrl}${id}/`
      );
      url.searchParams.append("lang", lang ?? "es");

      return await fetch(url, { headers: headers });
    } else {
      throw new Error("No se encontró el token");
    }
  }

  static async delete({ id }, lang) {
    const token = Coockies.get(COOKIE_NAME);
    const url = new URL(`${process.env.VUE_APP_API}${this.endpointUrl}${id}/`);

    if (token) {
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      url.searchParams.append("lang", lang ?? "es");

      return await fetch(url, {
        method: "DELETE",
        headers: headers,
      });
    } else {
      throw new Error("No se encontró el token");
    }
  }

  static async deactivate({ id }, lang) {
    const token = Coockies.get(COOKIE_NAME);
    const url = new URL(
      `${process.env.VUE_APP_API}${this.endpointUrl}${id}/inactivar/`
    );

    if (token) {
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      url.searchParams.append("lang", lang ?? "es");

      return await fetch(url, {
        method: "POST",
        headers: headers,
      });
    } else {
      throw new Error("No se encontró el token");
    }
  }

  static async isAdminUser() {
    const isValidToken = this.verifyToken();

    if (isValidToken) {
      const token = Coockies.get(COOKIE_NAME);

      if (token) {
        const headers = {
          Authorization: `Bearer ${token}`,
        };
        const url = new URL(`${process.env.VUE_APP_API}is-admin-user/`);

        return await fetch(url, { headers: headers });
      } else {
        throw new Error("No se encontró el token");
      }
    } else {
      return false;
    }
  }

  static async verifyToken() {
    const token = Coockies.get(COOKIE_NAME);
    const url = new URL(`${process.env.VUE_APP_API}token/verify/`);

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token }),
    });

    if (response.status >= 200 && response.status < 300) {
      return true;
    } else if (response.status === 401) {
      // Token inválido o expirado
      return await this.refreshAccessToken(); // Intenta refrescar el token
    }

    return false;
  }

  static async refreshAccessToken() {
    const refreshToken = Coockies.get("_riart_refreshToken");
    if (!refreshToken) {
      throw new Error("No se encontró el refresh token");
    }

    const url = new URL(`${process.env.VUE_APP_API}token/refresh/`);
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ refresh: refreshToken }),
    });

    if (response.status >= 200 && response.status < 300) {
      const data = await response.json();
      Coockies.set(COOKIE_NAME, data.access); // Guarda el nuevo access token
      return true;
    } else {
      throw new Error("No se pudo refrescar el token");
    }
  }
}
