import OrigenService from "@/api/origenes/origenes";

const OrigenStore = {
  state: {
    origenList: [],
    form: {
      descripcion: null,
    },
  },
  mutations: {
    SET_ORIGEN_LIST: (state, origenList) => (state.origenList = origenList),
    SET_ORIGEN: (state, origen) => (state.form = origen),
  },
  actions: {
    async getOrigenList({ dispatch, getters }, { search, page, pageSize }) {
      const lang = getters.getLang;
      const res = await OrigenService.list(search, page, pageSize, lang);

      switch (res.status) {
        case 401:
          dispatch("redirect", "/auth/login");
          break;
        case 404:
          dispatch("redirect", "/");
          break;
        default:
          return res;
      }
    },
    async saveOrigen({ state, dispatch }) {
      const res = state.form.id ? await OrigenService.edit(state.form) : await OrigenService.create(state.form);

      switch (res.status) {
        case 401:
          dispatch("redirect", "/auth/login");
          break;
        case 404:
          dispatch("redirect", "/");
          break;
        default:
          return res;
      }
    },
    async getOrigenById({ commit, dispatch, getters }, { id }) {
      const lang = getters.getLang;
      const res = await OrigenService.getById({ id }, lang);
      let data = null;

      switch (res.status) {
        case 401:
          dispatch("redirect", "/auth/login");
          break;
        case 404:
          dispatch("redirect", "/");
          break;
        default:
          data = await res.json();
          commit("SET_ORIGEN", data);

          return data;
      }
    },
    setOrigenList({ commit }, list) { commit('SET_ORIGEN_LIST', list) },
    async deactivateOrigen ({ dispatch }, { id }) {
      const res = await OrigenService.deactivate({ id });

      switch (res.status) {
        case 401:
          dispatch("redirect", "/auth/login");
          break;
        case 404:
          dispatch("redirect", "/");
          break;
        default:
          return res;
      }
    }
  },
  getters: {
    getOrigenListState: (state) => state.origenList
  },
};

export default OrigenStore;
