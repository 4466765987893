<template>
  <div>
    <multiselect
      v-model="negocio"
      :placeholder="$t('forms.negocio.fieldsPlaceholder.descripcion')"
      label="descripcion"
      :disabled="isOnlyView"
      :internal-search="false"
      :clear-on-select="false"
      :allow-empty="false"
      :options-limit="300"
      :options="getNegocioListState"
      @close="onTouch"
      @search-change="findNegocio"
      @select="handleSelected"
    >
    </multiselect>
  </div>
</template>
<script>
import Multiselect from "vue-multiselect";
import { baseMixin } from "@/mixins/base";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    Multiselect,
  },
  mixins: [baseMixin],
  data() {
    return {
      negocio: null,
      isTouched: false
    }
  },
  props: {
    modelValue: {
      type: [String, Number, Object],
      required: true
    }
  },
  async created() {
    await this.findNegocio(null);
    this.negocio = this.getNegocioListState.find(r => r.id === this.modelValue)
    this.handleSelected(this.negocio)
  },
  computed: {
    ...mapGetters(['getNegocioListState'])
  },
  mounted() {
    
  },
  methods: {
    ...mapActions(['getNegocioList', 'setNegocioList']),
    async findNegocio(query) {
      const res = await this.getNegocioList({ search: query, page: 1 });
       const { results } = await res.json();
      this.setNegocioList(results);
    },
     onTouch() {
      this.isTouched = true;
    },
    handleSelected(value) {
      this.$emit("update:modelValue", value)
      this.$emit('update-rubro', value)
    }
  },
  watch: {
    modelValue: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.negocio = newValue
      }
    }
  }
};
</script>
