<template>
  <div class="relative w-full mb-3">
    <label class="block text-blueGray-600 text-xs font-bold mb-2" :for="name">
      {{ label }}
    </label>
    <div class="input-container" :class="disabled ? 'disabled' : ''">
      <input
        :id="name"
        :ref="name"
        :disabled="disabled"
        :type="type"
        :class="inputClass"
        :placeholder="placeholder"
        :value="modelValue"
        :autocomplete="autocomplete"
        :min="min"
        @input="handleChange"
      />
    </div>
      <p
        v-if="showErrorMessage"
        class="text-red-500 text-xs italic font-weight-bold mt-1"
      >
        {{ errorMessage }}
      </p>
    
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    label: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "text",
    },
    min: {
      type: Number,
    },
    placeholder: {
      type: String,
      default: "",
    },
    modelValue: {
      default: "",
    },
    validate: {
      type: String,
      default: "",
    },
    autocomplete: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: () => "custom-input-" + Math.random().toString(36).substr(2, 9),
    },
    labelClass: {
      type: String,
      required: false,
      default: "",
    },
    validations: {
      type: [String, Object],
      default: "",
    },
    upperCase: {
      type: Boolean,
      default: true,
    },
    showErrorMessage: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      internalValue: this.modelValue,
    };
  },
  computed: {
    inputClass() {
      return [
        "px-3",
        "py-3",
        "placeholder-blueGray-300",
        "text-blueGray-700",
        "bg-white",
        "text-sm",
        "focus:outline-none",
        "focus:ring",
        "w-full",
        "ease-linear",
        "transition-all",
        "duration-150",
        this.upperCase ? "uppercase" : "",
        this.disabled ? "disabled" : "",
      ];
    },
    ...mapGetters(["getLang"]),
  },
  watch: {
    modelValue(val) {
      this.internalValue = val;
    },
    internalValue(val) {
      this.$emit("update:modelValue", val);
    },
  },
  methods: {
    handleChange(event) {
      this.$emit("input", event.target.value);
      this.$emit("update:modelValue", event.target.value);
    },
    toggleLanguage() {
      this.setLang(this.getLang);
    },
    ...mapActions(["setLang"]),
  },
};
</script>

<style scoped>
.font-weight-bold {
  font-weight: bold;
}
.text-red-500 {
  color: #ef4444; /* Mantener el color rojo */
}
.disabled {
  background: rgba(241, 245, 249, 0.824);
  opacity: 0.6;
}
.input-container {
  min-height: 40px;
  display: block;
  padding: 8px 8px 0 8px;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  background: #fff;
  font-size: 14px;

  & input {
    position: relative;
    display: inline-block;
    min-height: 20px;
    line-height: 20px;
    border: none;
    background: #fff;
    color: #35495e;
    padding: 0 0 0 5px;
    width: calc(100%);
    -webkit-transition: border 0.1s ease;
    transition: border 0.1s ease;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin-bottom: 8px;
    vertical-align: top;
    outline: none;
  }
}

input {
  outline: none;
  outline-color: transparent;
  border: none;
}
*:focus {
  --tw-ring-color: transparent;
}
.focus\:ring:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: transparent;
  -webkit-box-shadow: transparent;
}
</style>
