<template>
  <transition name="fade">
    <dialog
      v-if="!showPopup"
      :class="popupBackgrounClass"
      :style="{ 'z-index': '200', alignItems: 'center' }"
      role="alertdialog"
      aria-modal="true"
      aria-labelledby="dialogTitle"
      aria-describedby="dialogMessage"
    >
      <div class="px-6 py-6">
        <div v-if="popup.title" class="text-center mb-4">
          <h3
            id="dialogTitle"
            class="text-xl font-semibold leading-normal text-blueGray-700"
          >
            {{ popup.title }}
          </h3>
        </div>
        <div class="py-10 border-blueGray-200 text-center">
          <div class="flex flex-wrap justify-center">
            <div class="w-full">
              <p
                id="dialogMessage"
                class="mb-4 text-lg leading-relaxed text-blueGray-700"
              >
                {{ popup.message }}
              </p>
              <div class="flex flex-auto justify-around px-4 lg:px- py-4">
                <button
                  class="bg-blueGray-100 text-blueGray-500 font-bold uppercase text-xs px-6 py-2 rounded shadow outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                  type="button"
                  aria-label="Close dialog"
                  @click.prevent.stop="emitEvent"
                >
                  {{ $t("btnLabels.ok") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </dialog>
  </transition>
</template>

<script>
export default {
  props: {
    popup: {
      type: Object,
      required: true,
    },
    showPopup: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    popupBackgrounClass() {
      return [
        "absolute",
        "flex",
        "flex-col",
        "min-w-0",
        "break-words",
        "lg:w-8/12",
        "shadow-xl",
        "rounded-lg",
        this.popupClass,
      ];
    },
    popupClass() {
      switch (this.popup.type) {
        case "success":
          return "bg-emerald-200";
        case "error":
          return "bg-red-400";
        case "info":
          return "bg-lightBlue-200";
        default:
          return "bg-white";
      }
    },
  },
  methods: {
    emitEvent() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Color oscuro semitransparente */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Asegura que esté por encima de otros elementos */
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}
.fade-enter,
.fade-leave-to /* .fade-leave-active en versiones anteriores de Vue */ {
  opacity: 0;
}
</style>
