const ModalPreguntasStore = {
  state: {
    modalForm: {
      descripcion: null,
      cerrada: false,
      multiple: false,
      beneficio: false,
      respuestas: [],
    },
  },
  mutations: {
    SET_MODAL_FORM: (state, modalForm) => state.modalForm = modalForm,
    SET_RESPUESTAS: (state, respuestas) => state.modalForm.respuestas = respuestas,
    SET_NEW_RESPUESTA: (state, respuesta) => state.modalForm.respuestas.push(respuesta)
  },
  actions: {
    setInitModalForm({ commit }) {
      const initForm = {
        descripcion: null,
        cerrada: false,
        multiple: false,
        beneficio: false,
        respuestas: [],
      }
      commit('SET_MODAL_FORM', initForm)
    },
    setModalForm ({ commit }, modalForm) {
      commit('SET_MODAL_FORM', modalForm)
    },
    setNewRespuesta ({ commit }) {
      commit('SET_NEW_RESPUESTA', { descripcion: null })
    }
  },
  getters: {
    getQuestionModalForm: (state) => state.modalForm
  },
};

export default ModalPreguntasStore;
