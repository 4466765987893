<template>
  <div>
    <div id="reportContainer" style="height: 500px"></div>
  </div>
</template>

<script>
import axios from "axios";
import Coockies from "js-cookie";
const COOKIE_NAME = "_riart_accessToken";

export default {
  name: "Reportes",
  data() {
    return {
      embedToken: "",
      embedUrl:
        "https://app.powerbi.com/reportEmbed?reportId=28c9b3de-82b7-4a68-8156-8aeb2b3ccb6c&ctid=a355b463-427c-4234-8d56-cd1e9133dba3", // EMBED_URL
      reportId: "28c9b3de-82b7-4a68-8156-8aeb2b3ccb6c",
    };
  },
  async created() {
    await this.getEmbedToken();
  },
  methods: {
    async getEmbedToken() {
      try {
        const token = Coockies.get(COOKIE_NAME);
        // Llama al endpoint del backend en Django para obtener el Embed Token
        const response = await axios.get(
          `${process.env.VUE_APP_API}getEmbedToken`,
          {
            headers: {
              Authorization: `Bearer ${token}`, // Agrega el Bearer Token aquí
            },
          }
        );
        this.embedToken = response.data.embedToken;
        this.embedReport();
      } catch (error) {
        console.error("Error al obtener el Embed Token:", error);
      }
    },
    embedReport() {
      // Asegúrate de que el SDK de Power BI esté cargado
      const models = window["powerbi-client"].models;

      // Configuración del reporte
      const embedConfiguration = {
        type: "report",
        id: this.reportId,
        embedUrl: this.embedUrl,
        accessToken: this.embedToken,
        tokenType: models.TokenType.Embed,
        settings: {
          panes: {
            filters: {
              visible: false,
            },
          },
        },
      };

      // Embeber el reporte en el contenedor
      const reportContainer = document.getElementById("reportContainer");
      window.powerbi.embed(reportContainer, embedConfiguration);
    },
  },
};
</script>

<style>
#reportContainer {
  width: 100%;
}
</style>
