<template>
  <nav class="relative px-2 flex justify-center my-4">
    <ul class="pagination">
      <li 
        class="page-item" 
        :class="{ disabled: isInFirstPage }"
      >
        <button 
          class="page-link"
          type="button"
          @click="onClickFirstPage"
          :disabled="isInFirstPage"
        >
          {{ $t('baseList.pagination.first')}}
        </button>
      </li>
      <li 
        class="page-item" 
        :class="{ disabled: isInFirstPage }"
      >
        <button
          class="page-link"
          type="button"
          @click="onClickPreviousPage"
          :disabled="isInFirstPage"
        >
          {{ $t('baseList.pagination.previous')}}
        </button>
      </li>

      <!-- Visible buttons start -->
      <li 
        v-for="page in pages" :key="page" 
        class="page-item" 
        :class="{ active: isPageActive(page.name) }"
        >
        <button
          class="page-link"
          @click="onClickPage(page.name)" 
          type="button"
          :disabled="page.isDisabled"
        >
          {{ page.name }}
        </button>
      </li>
      <!-- Visible buttons end -->

      <li 
        class="page-item" 
        :class="{ disabled: isInLastPage }"
      >
        <button
          class="page-link"
          type="button"
          @click="onClickNextPage"
          :disabled="isInLastPage"
        >
          {{ $t('baseList.pagination.next')}}
        </button>
      </li>
      <li 
        class="page-item" 
        :class="{ disabled: isInLastPage }"
      >
        <button 
          class="page-link" 
          :class="{ disabled: isInLastPage }"
          @click="onClickLastPage" 
          type="button"
          :disabled="isInLastPage"
        >
          {{ $t('baseList.pagination.last')}}
        </button>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  props: {
    maxVisibleButtons: {
      type: Number,
      required: false,
      default: 3
    },
    perPage: {
      type: Number,
      required: true
    },
    totalItems: {
      type: Number,
      required: true
    },
    currentPage: {
      type: Number,
      required: true
    }
  },
  computed: {
    totalPages() {
      if (this.totalItems > 0)
        return Math.ceil(this.totalItems / this.perPage)
      
      return 1
    },
    startPage() {
      // When on the first page
      if (this.currentPage === 1) {
        return 1;
      }

      // When on the last page
      if (this.currentPage === this.totalPages) {
        const start = this.totalPages - (this.maxVisibleButtons - 1);
        return start === 0 ? 1 : start
      }

      // When inbetween
      return this.currentPage - 1;
    },
    pages() {
      const range = [];

      for (
        let i = this.startPage;
        i <= Math.min(this.startPage + this.maxVisibleButtons - 1, this.totalPages);
        i++
      ) {
        range.push({
          name: i,
          isDisabled: i === this.currentPage
        });
      }
      return range;
    },
    isInFirstPage() { return this.currentPage === 1 },
    isInLastPage() { return this.currentPage === this.totalPages },
  },
  methods: {
    isPageActive(page) { return this.currentPage === page },
    onClickFirstPage() { this.$emit('pageChanged', 1) },
    onClickPreviousPage() { this.$emit('pageChanged', this.currentPage - 1) },
    onClickPage(page) { this.$emit('pageChanged', page) },
    onClickNextPage() { this.$emit('pageChanged', this.currentPage + 1) },
    onClickLastPage() { this.$emit('pageChanged', this.totalPages) }
  },
  emits: ['pageChanged'],
  watch: {
    totalItems: {
      deep: true,
      handler() {
        this.$forceUpdate();
      }
    }
  }
};
</script>

<style>
.active {
  background-color: #4AAE9B;
  color: #ffffff;
}

.pagination {
  display: inline-block;
}

.pagination .page-item {
  display: inline;
  margin: 0 5px;
  padding: 1rem;
}

.pagination .page-item.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.pagination .page-item.active {
  color: #fff;
  background-color: #4e6b9b;
  border-color: #4e6b9b;
}
</style>