import { createStore } from 'vuex';

import Auth from '@/store/auth/auth';
import Users from '@/store/users/users';
import GroupStore from '@/store/users/groups';
import PermissionStore from '@/store/users/permissions';
import NegocioStore from '@/store/negocios/negocios';
import SegmentoStore from '@/store/segmentos/segmentos';
import EmpresaStore from '@/store/empresas/empresas';
import EncuestaStore from '@/store/encuestas/encuestas';
import ModalPreguntas from '@/store/secciones/modalPreguntas';
import TiposRespuestaStore from '@/store/secciones/tipo_respuesta';
import SeccionesStore from '@/store/secciones/secciones';
import OrigenStore from '@/store/origenes/origenes';
import PeriodoStore from '@/store/periodos/periodos';
import PeriodoActivoStore from '@/store/periodos_activos/periodos_activos';
import IdiomaStore from '@/store/secciones/idiomas';


const store = createStore({
  state: {
    count: 1,
    page: 1,
    pageSize: 10,
    tableColumns: [],
    alertOpen: false,
    showOverlay: false,
    lang: 'es',
    popup: {
      type: "info",
      show: false,
      message: "",
    },
  },
  mutations: {
    setCount: (state, count) => (state.count = count),
    setPage: (state, page) => (state.page = page),
    setPageSize: (state, pageSize) => (state.pageSize = pageSize),
    setTableColumns: (state, tableColumns) =>
      (state.tableColumns = tableColumns),
    setAlertOpen: (state) => (state.alertOpen = !state.alertOpen),
    setPopup: (state, popup) => (state.popup = popup),
    setShowOverlay: (state, showOverlay) => (state.showOverlay = showOverlay),
    setLang: (state, lang) => (state.lang = lang),
  },
  actions: {
    setCount({ commit }, count) {
      commit("setCount", count);
    },
    setPage({ commit }, page) {
      commit("setPage", page);
    },
    setPageSize({ commit }, pageSize) {
      commit("setPageSize", pageSize);
    },
    setTableColumns({ commit }, tableColumns) {
      commit("setTableColumns", tableColumns);
    },
    setAlertOpen({ commit }) {
      commit("setAlertOpen");
    },
    setPopup({ commit }, { show, type, title, message }) {
      commit("setPopup", { show, type, title, message });
    },
    setShowOverlay({ commit }, showOverlay) {
      commit("setShowOverlay", showOverlay);
    },
    setLang({ commit }, lang) {
      commit("setLang", lang);
    },
  },
  getters: {
    getCount: (state) => state.count,
    getPage: (state) => state.page,
    getPageSize: (state) => state.pageSize,
    getTableColumns: (state) => state.getTableColumns,
    getShowOverlay: (state) => state.showOverlay,
    getLang: (state) => state.lang,
  },
  modules: {
    auth: Auth,
    users: Users,
    groups: GroupStore,
    permissions: PermissionStore,
    empresas: EmpresaStore,
    encuestas: EncuestaStore,
    negocios: NegocioStore,
    segmentos: SegmentoStore,
    modalPreguntas: ModalPreguntas,
    tiposRespuesta: TiposRespuestaStore,
    secciones: SeccionesStore,
    origenes: OrigenStore,
    periodos: PeriodoStore,
    periodosActivos: PeriodoActivoStore,
    idiomas: IdiomaStore,
  },
});

export default store