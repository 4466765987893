import IdiomaService from "@/api/encuestas/idioma";

const IdiomaStore = {
  state: {
    idiomaList: [],
    form: {},
  },
  mutations: {
    SET_IDIOMA: (state, payload) => (state.form = payload),
    SET_IDIOMA_LIST: (state, list) => (state.idiomaList = list),
  },
  actions: {
    async getIdiomaList({ dispatch }, { search, page, pageSize }) {
      const res = await IdiomaService.list(search, page, pageSize);

      switch (res.status) {
        case 401:
          dispatch("redirect", "/auth/login");
          break;
        case 404:
          dispatch("redirect", "/");
          break;
        default:
          return res;
      }
    },
    setIdioma({ commit }, payload) {
      commit("SET_IDIOMA", payload);
    },
    async saveIdioma({ state, dispatch }) {
      const res = state.form.id
        ? await IdiomaService.edit(state.form)
        : await IdiomaService.create(state.form);

      switch (res.status) {
        case 401:
          dispatch("redirect", "/auth/login");
          break;
        case 404:
          dispatch("redirect", "/");
          break;
        default:
          return res;
      }
    },
    async getIdiomaById({ dispatch }, { id }) {
      const res = await IdiomaService.getById({ id });
      let data = null;

      switch (res.status) {
        case 401:
          dispatch("redirect", "/auth/login");
          break;
        case 404:
          dispatch("redirect", "/");
          break;
        default:
          data = await res.json();
      }

      return data;
    },
    setIdiomaList({ commit }, list) {
      commit("SET_IDIOMA_LIST", list);
    },
    async deactivateIdioma({ dispatch }, { id }) {
      const res = await IdiomaService.deactivate({ id });

      switch (res.status) {
        case 401:
          dispatch("redirect", "/auth/login");
          break;
        case 404:
          dispatch("redirect", "/");
          break;
        default:
          return res;
      }
    },
  },
  getters: {
    getIdioma: (state) => state.form,
    getIdiomaListState: (state) => state.idiomaList,
  },
};

export default IdiomaStore;
