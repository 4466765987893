<template>
  <div class="flex items-center"
  >
    <popup
      v-if="showPopup"
      :popup="popupData"
      @close="handleOk"
    />
  <base-table
    :title="$t('forms.negocio.titlePlural')"
    :records="records"
    @update:records="(val) => (records = val)"
    :table-columns="tableColumns"
    @update:table-columns="(val) => (tableColumns = val)"
    @add-row="addRow"
    @edit-row="editRow"
    @view-row="viewRow"
    @delete-row="deleteRow"
    @pageChanged="onChangePage"
    @search="searchInList"
    @reload-page="searchInList({ search: null })"
    :lang-dict="langDict"
  />
  </div>
</template>
<script>
import BaseTable from "@/components/Cards/BaseTable";
import Popup from "@/components/Cards/Popup.vue";
import { mapActions, mapGetters } from "vuex";
import { baseMixin } from "@/mixins/base";

export default {
  components: {
    BaseTable,
    Popup
  },
  data() {
    return {
      records: [],
      tableColumns: [],
      langDict: "forms.negocio.tableColumns",
    };
  },
  async created() {
    await this.searchInList({ search: null });
  },
  computed: {
    ...mapGetters(["getPageSize", "getPage"]),
  },
  mixins: [baseMixin],
  methods: {
    ...mapActions([
      "getNegocioList",
      "setNegocioList",
      "setTableColumns",
      "setPage",
      "setCount",
      "setShowOverlay",
      "deactivateNegocio"
    ]),
    async addRow() {
      this.$router.push({ name: "AddNegocio" });
    },
    async editRow(id) {
      this.$router.push({ name: "EditNegocio", params: { id } });
    },
    async viewRow(id) {
      this.$router.push({ name: "ViewNegocio", params: { id } });
    },
    async deleteRow(id) {
       try {
      const res = await this.deactivateNegocio({ id });

      if (res.status >= 200 && res.status < 300) {
        this.popupData = {
          type: "success",
          message: "La operación se completó con éxito",
        };
      }
      this.showPopup = true;
    } catch (err) {
      this.popupData = {
        type: "error",
        message: err,
      };
      this.showPopup = true;
    } finally {
      this.search = null;
      this.searchInList({ search: this.search });
      this.setShowOverlay(false);
    }
    },
    async searchInList({ search }) {
      this.setShowOverlay(true);

      const res = await this.getNegocioList({
        search: search,
        page: this.getPage,
        pageSize: this.getPageSize,
      });

      if (res.status === 200) {
        const { results, tableColumns, count } = await res.json();

        this.records = results;
        this.tableColumns = tableColumns;
        this.setNegocioList(results);
        this.setCount(count);
        this.setTableColumns(tableColumns);
      }
      this.setShowOverlay(false);
    },
    async onChangePage(page) {
      this.setPage(page);
      await this.searchInList({ search: null });
    },
  },
};
</script>
