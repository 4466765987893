<template>
  <nav
    class="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl bg-white flex flex-wrap items-center justify-between relative md:w-64 z-10"
  >
    <div
      class="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto"
    >
      <!-- Collapse -->
      <div
        class="md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:shadow-none absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded"
        v-bind:class="collapseShow"
      >
        <router-link to="/">
          <img
            alt="Riart Consulting"
            :src="logo"
            :style="{ height: 'auto' }"
            class="mx-auto py-12 px-2"
          />
        </router-link>

        <hr />
        <router-link class="px-4" to="/" v-slot="{ href, navigate, isActive }">
          <a
            :href="href"
            @click="navigate"
            class="text-xs uppercase py-2 font-bold block"
            :class="[
              isActive
                ? 'text-blueGray-500 hover:text-blueGray-500'
                : 'color-secondary-500 hover:color-secondary-500',
            ]"
          >
            <i class="fas fa-home mr-2 text-sm color-primary"></i>
            {{ $t("home.title") }}
          </a>
        </router-link>
        <router-link class="px-4" to="/reportes/" v-slot="{ href, navigate, isActive }">
          <a
            :href="href"
            @click="navigate"
            class="text-xs uppercase py-2 font-bold block"
            :class="[
              isActive
                ? 'text-blueGray-500 hover:text-blueGray-500'
                : 'color-secondary-500 hover:color-secondary-500',
            ]"
          >
            <i class="fas fa-poll color-primary text-sm"></i>
            {{ `Reportes` }}
          </a>
        </router-link>
        <router-link
          v-if="!getIsAdminUser"
          class="px-4"
          to="/completar_encuesta/add"
          v-slot="{ href, navigate, isActive }"
        >
          <a
            :href="href"
            @click="navigate"
            class="text-xs uppercase py-2 font-bold block"
            :class="[
              isActive
                ? 'text-blueGray-500 hover:text-blueGray-500'
                : 'color-secondary-500 hover:color-secondary-500',
            ]"
          >
            <i class="far fa-list-alt mr-2 text-sm color-primary"></i>
            {{ $t("forms.encuesta.completar") }}
          </a>
        </router-link>
        <router-link
          v-if="!getIsAdminUser"
          class="px-4"
          to="/completar_encuesta"
          v-slot="{ href, navigate, isActive }"
        >
          <a
            :href="href"
            @click="navigate"
            class="text-xs uppercase py-2 font-bold block"
            :class="[
              isActive
                ? 'text-blueGray-500 hover:text-blueGray-500'
                : 'color-secondary-500 hover:color-secondary-500',
            ]"
          >
            <i class="fa-solid fa-list-ul mr-2 text-sm color-primary"></i>
            {{ $t("forms.encuestasCompuestas.pluralTitle") }}
          </a>
        </router-link>
        <ul class="md:hidden items-center flex flex-wrap list-none">
          <li class="inline-block relative">
            <notification-dropdown />
          </li>
          <li class="inline-block relative">
            <user-dropdown />
          </li>
        </ul>

        <div v-if="getIsAdminUser">
          <!-- User -->
          <li class="flex flex-col items-start px-4">
            <div
              class="flex items-center justify-between w-full"
              @click="toggleMenuCollapse('isUserMenuCollapsed')"
            >
              <a
                class="flex text-xs uppercase py-2 font-bold cursor-pointer flex-1"
                :class="[
                  !menuCollapsed.isUserMenuCollapsed
                    ? 'text-blueGray-500 hover:text-blueGray-500'
                    : 'color-secondary-500 hover:color-secondary-500',
                ]"
              >
                <i :class="['fa fa-user', 'mr-2 text-sm color-primary']"></i>
                <span class="w-full">{{ $t("message.user.users") }}</span>
                <i
                  :class="[
                    'fas',
                    menuCollapsed.isUserMenuCollapsed
                      ? 'fa-chevron-down'
                      : 'fa-chevron-up',
                    'cursor-pointer',
                    'ml-2',
                  ]"
                ></i>
              </a>
            </div>
            <transition name="slide-fade">
              <ul
                v-if="!menuCollapsed.isUserMenuCollapsed"
                class="md:flex-col md:min-w-full flex flex-col list-none pl-4"
              >
                <!-- <li>
                  <sidebar-menu
                    permission-name="view_group"
                    route-name="/groups/"
                    :title="$t('message.user.groups.groups')"
                  />
                </li> -->
                <li>
                  <sidebar-menu
                    permission-name="view_usuarioempresa"
                    route-name="/users/"
                    :title="$t('forms.user.titlePlural')"
                  />
                </li>
                <li>
                  <sidebar-menu
                    permission-name="view_usuarioempresa"
                    route-name="/users/change_password"
                    title="Restablecer Contraseñas"
                  />
                </li>
              </ul>
            </transition>
          </li>

          <!-- Company -->
          <li class="flex flex-col items-start px-4">
            <div
              class="flex items-center justify-between w-full"
              @click="toggleMenuCollapse('isCompanyMenuCollapsed')"
            >
              <a
                class="flex text-xs uppercase py-2 font-bold block cursor-pointer flex-1"
                :class="[
                  !menuCollapsed.isCompanyMenuCollapsed
                    ? 'text-blueGray-500 hover:text-blueGray-500'
                    : 'color-secondary-500 hover:color-secondary-500',
                ]"
              >
                <i
                  :class="['fa fa-building', 'mr-2 text-sm color-primary']"
                ></i>
                <span class="w-full">
                  {{ $t("forms.empresa.titlePlural") }}
                </span>
                <i
                  :class="[
                    'fas',
                    menuCollapsed.isCompanyMenuCollapsed
                      ? 'fa-chevron-down'
                      : 'fa-chevron-up',
                    'cursor-pointer',
                    'ml-2',
                  ]"
                ></i>
              </a>
            </div>
            <transition name="slide-fade">
              <ul
                v-if="!menuCollapsed.isCompanyMenuCollapsed"
                class="md:flex-col md:min-w-full flex flex-col list-none pl-4"
              >
                <li>
                  <sidebar-menu
                    permission-name="view_rubro"
                    route-name="/negocios/"
                    :title="$t('forms.negocio.titlePlural')"
                  />
                </li>
                <li>
                  <sidebar-menu
                    permission-name="view_origen"
                    route-name="/origenes/"
                    :title="$t('forms.origen.pluralTitle')"
                  />
                </li>
                <li>
                  <sidebar-menu
                    permission-name="view_empresa"
                    route-name="/empresas/"
                    :title="$t('forms.empresa.titlePlural')"
                  />
                </li>
              </ul>
            </transition>
          </li>

          <!-- Survey -->
          <li class="flex flex-col items-start px-4">
            <div
              class="flex items-center justify-between w-full"
              @click="toggleMenuCollapse('isSurveyMenuCollapsed')"
            >
              <a
                class="flex text-xs uppercase py-2 font-bold block cursor-pointer flex-1"
                :class="[
                  !menuCollapsed.isSurveyMenuCollapsed
                    ? 'text-blueGray-500 hover:text-blueGray-500'
                    : 'color-secondary-500 hover:color-secondary-500',
                ]"
              >
                <i :class="['fa fa-table', 'mr-2 text-sm color-primary']"></i>
                <span class="w-full">
                  {{ $t("forms.encuesta.pluralTitle") }}
                </span>
                <i
                  :class="[
                    'fas',
                    menuCollapsed.isSurveyMenuCollapsed
                      ? 'fa-chevron-down'
                      : 'fa-chevron-up',
                    'cursor-pointer',
                    'ml-2',
                  ]"
                ></i>
              </a>
            </div>
            <transition name="slide-fade">
              <ul
                v-if="!menuCollapsed.isSurveyMenuCollapsed"
                class="md:flex-col md:min-w-full flex flex-col list-none pl-4"
              >
                <li>
                  <sidebar-menu
                    permission-name="view_periodo"
                    route-name="/periodos/"
                    :title="$t('forms.periodo.pluralTitle')"
                  />
                </li>
                <li>
                  <sidebar-menu
                    permission-name="view_periodoactivo"
                    route-name="/periodos_activos/"
                    :title="$t('forms.periodoActivo.pluralTitle')"
                  />
                </li>
                <li>
                  <sidebar-menu
                    permission-name="view_idioma"
                    route-name="/idiomas/"
                    :title="$t('forms.idiomas.pluralTitle')"
                  />
                </li>
                <li>
                  <sidebar-menu
                    permission-name="view_seccion"
                    route-name="/secciones/"
                    :title="$t('forms.seccion.pluralTitle')"
                  />
                </li>
                <li>
                  <sidebar-menu
                    permission-name="view_encuesta"
                    route-name="/encuestas/"
                    :title="$t('forms.encuesta.pluralTitle')"
                  />
                </li>
              </ul>
            </transition>
          </li>
        </div>
      </div>
      <hr />
      <router-link class="px-4" to="/">
        <a
          href="javascript:void(0);"
          class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
          @click.stop.prevent="logout"
        >
          <i class="fas fa-power-off"></i>
          {{ $t("message.login.logout") }}
        </a>
      </router-link>
    </div>
  </nav>
</template>

<script>
import NotificationDropdown from "@/components/Dropdowns/NotificationDropdown.vue";
import UserDropdown from "@/components/Dropdowns/UserDropdown.vue";
import SidebarMenu from "@/components/Sidebar/SidebarMenu.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      collapseShow: "hidden",
      logo: require("@/assets/img/riart-consulting-logo.jpg"),
      menuCollapsed: {
        isUserMenuCollapsed: true,
        isCompanyMenuCollapsed: true,
        isSurveyMenuCollapsed: true,
      },
    };
  },
  async created() {
    await this.$store.dispatch("getCurrentUserPermissionsByUser");
    await this.checkAdminStatus();
  },
  computed: {
    ...mapGetters(["getIsAdminUser"]),
  },
  methods: {
    ...mapActions(["checkAdminStatus"]),
    logout() {
      this.$store.dispatch("logout");
    },
    toggleCollapseShow: function (classes) {
      this.collapseShow = classes;
    },
    hasPermission(code) {
      return this.$store.dispatch("hasCurrentUserPermission", code);
    },
    toggleMenuCollapse(key) {
      for (let menu in this.menuCollapsed) {
        this.menuCollapsed[menu] =
          menu === key ? !this.menuCollapsed[menu] : true;
      }
    },
  },
  components: {
    NotificationDropdown,
    UserDropdown,
    SidebarMenu,
  },
};
</script>

<style scoped>
ul {
  list-style: none;
  padding-left: 1rem;
}
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0s ease;
}
.slide-fade-enter, .slide-fade-leave-to /* .slide-fade-leave-active in <2.1.8 */ {
  /* transform: translateY(-10px); */
  opacity: 0;
  height: 0;
  padding: 0;
}
</style>
