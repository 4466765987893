<template>
  <div>
    <multiselect
      v-model="periodo"
      :placeholder="$t('forms.periodoActivo.fieldsPlaceholder.periodo')"
      :custom-label="getLabel"
      :disabled="isOnlyView"
      :internal-search="false"
      :clear-on-select="false"
      :allow-empty="false"
      :options-limit="300"
      :options="getPeriodoListState"
      @close="onTouch"
      @search-change="findPeriodo"
      @select="handleSelected"
    >
    </multiselect>
  </div>
</template>
<script>
import Multiselect from "vue-multiselect";
import { baseMixin } from "@/mixins/base";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    Multiselect,
  },
  mixins: [baseMixin],
  data() {
    return {
      periodo: null,
      isTouched: false
    }
  },
  props: {
    modelValue: {
      type: [String, Number, Object, null],
      required: true
    }
  },
  computed: {
    ...mapGetters(['getPeriodoListState'])
  },
  methods: {
    ...mapActions(['getPeriodoList', 'setPeriodoList']),
    async findPeriodo(query) {
      const res = await this.getPeriodoList({ search: query, page: 1 });
      const { results } = await res.json();
      
       if (this.isEditView || this.isOnlyView) {
        this.periodo = results.filter(r => r.id === this.modelValue)
      }

      this.setPeriodoList(results);
    },
     onTouch() {
      this.isTouched = true;
    },
    handleSelected(value) {
      this.$emit('update:modelValue', value)
    },
    getLabel(val){
      return `${val.fecha} / ${val.fecha_fin}`
    }
  },
  watch: {
    modelValue: {
      deep: true,
      immediate: true,
      async handler(newValue) {
        await this.findPeriodo(newValue)
      }
    }
  }
};
</script>
