<template>
  <div>
    <multiselect
      v-model="segmento"
      :placeholder="$t('forms.segmento.fieldsPlaceholder.descripcion')"
      label="descripcion"
      :disabled="isOnlyView"
      :internal-search="false"
      :clear-on-select="false"
      :allow-empty="false"
      :options-limit="300"
      :options="getSegmentoListState"
      @close="onTouch"
      @search-change="findSegmento"
      @select="handleSelected"
    >
    </multiselect>
  </div>
</template>
<script>
import Multiselect from "vue-multiselect";
import { baseMixin } from "@/mixins/base";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    Multiselect,
  },
  mixins: [baseMixin],
  data() {
    return {
      segmento: null,
      isTouched: false,
    };
  },
  props: {
    modelValue: {
      type: [String, Number, Object],
      required: true,
    },
    rubro: {
      type: Object,
    },
  },
  async created() {
    // await this.findSegmento(null);
    // this.segmento = this.getSegmentoListState.find(
    //   (r) => r.id === this.modelValue
    // );
    // this.handleSelected(this.segmento);
  },
  computed: {
    ...mapGetters(["getSegmentoListState"]),
  },
  mounted() {},
  methods: {
    ...mapActions(["getSegmentoList", "setSegmentoList"]),
    async findSegmento(query) {
      let res;
      if (this.rubro?.id) {
        res = await this.getSegmentoList({
          search: query,
          page: 1,
          rubroId: this.rubro.id,
        });

        if (res.ok) {
          const { results } = await res.json();
          this.setSegmentoList(results);

          const segmentos = results.filter((r) => r.id === this.modelValue);
          this.segmento = this.isEditView || this.isOnlyView ? segmentos[0] : null;
        } else {
          this.setSegmentoList([]); // Limpia la lista si hay un error
        }
      } else {
        res = await this.getSegmentoList({
          search: query,
          page: 1,
        });
      }

      if (!res.ok) {
        // Verifica que la respuesta sea exitosa
        this.setSegmentoList([]); // Limpia la lista si hay un error
      }
    },
    onTouch() {
      this.isTouched = true;
    },
    handleSelected(value) {
      this.$emit("update:modelValue", value);
    },
  },
  watch: {
    rubro: {
      deep: true,
      immediate: true,
      async handler() {
        await this.findSegmento(null);
      },
    },
    // modelValue: {
    //   immediate: true,
    //   deep: true,
    //   handler(newValue) {
    //     this.segmento = newValue;
    //   },
    // },
  },
};
</script>
