<template>
  <!-- Header -->
  <div class="relative md:pt-28 pb-32 bg-primary">
    <div class="px-4 md:px-10 mx-auto w-full">
    </div>
  </div>
</template>

<script>
export default {
  name: "base-header"
};
</script>
