<template>
  <crud-base
    :title="title"
    :popup-data="popupData"
    :show-popup="showPopup"
    @cancel="returnToList()"
    @add-register="save"
    @ok="popupData.type === 'error' ? (showPopup = false) : returnToList()"
  >
    <template v-slot:form>
      <div class="flex-auto flex-col lg:px-2 py-2 pt-4">
        <form>
          <div class="flex">
            <div class="w-full lg:w-6/12 px-2">
              <div>
                <label
                  class="w-full block text-blueGray-600 text-xs font-bold mb-2"
                >
                  {{ $t("forms.user.fields.username") }}
                </label>
                <multiselect
                  v-model="userObj.user"
                  placeholder="Seleccione un usuario"
                  label="username"
                  :internal-search="false"
                  :allow-empty="false"
                  :options-limit="300"
                  :options="userList"
                  @search-change="debounceFind"
                />
              </div>
            </div>
            <div class="w-full lg:w-6/12 px-2">
              <custom-input
                :label="$t('forms.user.fields.password')"
                :disabled="isOnlyView"
                :placeholder="$t('forms.user.fieldsPlaceholder.password')"
                type="password"
                autocomplete="new-password"
                v-model="userObj.password1"
                :validations="'required'"
                :show-error-message="passwordError !== null"
                :error-message="passwordError"
              />
            </div>
            <div class="w-full lg:w-6/12 px-2">
              <custom-input
                :label="$t('forms.user.fields.confirmPassword')"
                :disabled="isOnlyView"
                type="password"
                :placeholder="
                  $t('forms.user.fieldsPlaceholder.confirmPassword')
                "
                v-model="userObj.password2"
                :validations="'required'"
                autocomplete="new-password"
                :show-error-message="confirmPasswordError !== null"
                :error-message="confirmPasswordError"
              />
            </div>
          </div>
        </form>
      </div>
    </template>
  </crud-base>
</template>
<script>
import CrudBase from "@/components/Cards/CrudBase.vue";
import CustomInput from "@/components/Inputs/CustomInput.vue";
import { baseMixin } from "@/mixins/base";
import Multiselect from "vue-multiselect";
import { mapActions } from "vuex";
import _ from "lodash";

export default {
  components: {
    CrudBase,
    CustomInput,
    Multiselect,
  },
  data() {
    return {
      module: "user",
      capitalizeModule: "User",
      listName: "ListUsers",
      isTouchedCompany: false,
      isTouchedGroup: false,
      userObj: {
        user: null,
        password1: null,
        password2: null,
      },
      userList: [],
    };
  },
  mixins: [baseMixin],
  computed: {
    passwordError() {
      const password = this.userObj.password1 ?? ""; // Asigna una cadena vacía si es null
      const regex = /^(?=.*[A-Z])(?=.*[!@#$%^&*()_+[\]{}|;:',.<>/?\\-]).{8,}$/;
      const errors = [];

      if (!password) return;

      if (!regex.test(password)) {
        if (password.length === 0 || password.length < 8) {
          errors.push("La contraseña debe tener al menos 8 caracteres.");
        }
        if (!/[A-Z]/.test(password)) {
          errors.push(
            "La contraseña debe contener al menos una letra mayúscula."
          );
        }
        if (!/[!@#$%^&*()_+[\]{}|;:',.<>/?\\-]/.test(password)) {
          errors.push(
            "La contraseña debe contener al menos un carácter especial."
          );
        }
      }

      return errors.length > 0 ? errors.join(" ") : null;
    },
    confirmPasswordError() {
      const password2 = this.userObj.password2 ?? ""; // Asigna una cadena vacía si es null
      const password1 = this.userObj.password1 ?? ""; // Asegúrate de que password1 también sea una cadena

      if (!password2) return null;
      if (password2 !== password1) {
        return "Las contraseñas no coinciden";
      }
      return null;
    },
  },
  async created () {
    this.setShowOverlay(true)
    await this.find()
    this.setShowOverlay(false)
  },
  methods: {
    ...mapActions([
      'getUserList',
      'updateUserPassword',
      'setShowOverlay',
    ]),
    debounceFind: _.debounce(function(query) {
      this.find(query);
    }, 300),
    async find(query) {
      const res = await this.getUserList({ search: query })
      const { results } = await res.json()
      this.userList = results;
    },
    validateAllFields() {
      const errors = [];

      // Validar username
      if (!this.userObj.user) {
        errors.push("El usuario es requerido.");
      }

      // Validar contraseña
      const passwordError = this.passwordError;
      if (passwordError) {
        errors.push(passwordError);
      }

      // Validar confirmación de contraseña
      const confirmPasswordError = this.confirmPasswordError;
      if (confirmPasswordError) {
        errors.push(confirmPasswordError);
      }

      // Si hay errores, mostrar mensaje y retornar false
      if (errors.length > 0) {
        this.popupData = {
          show: true,
          type: "error",
          title: this.$t("popup.error.title"),
          message: errors.join(" "),
        };
        this.showPopup = true;
        return false;
      }

      return true;
    },
    async save() {
      try {
        if (!this.validateAllFields()) {
          return
        }
        const obj = {
          user_id: this.userObj.user.id,
          password1: this.userObj.password1,
          password2: this.userObj.password2
        }
        console.log(obj)
        this.setShowOverlay(true)
        const res = await this.updateUserPassword(obj)
        const isOk = res.status >= 200 && res.status <= 300;

        if (isOk) {
          this.popupData = {
            show: true,
            type: isOk ? "success" : "error",
            title: this.$t("popup.success.title"),
            message: this.$t("popup.success.message")
          };
        } else {
          throw new Error(res)
        }
        this.showPopup = true;

      } catch(err) {
        this.setShowOverlay(false);
        console.log("err: ", err.message)
        this.popupData = {
          show: true,
          type: "error",
          title: this.$t("popup.error.title"),
          message: err.message.detail,
        };
        this.showPopup = true;
      } finally {
        this.setShowOverlay(false)
      }
    }
  },
};
</script>
