<template>
  <div class="flex items-center">
    <popup v-if="showPopup" :popup="popupData" @close="handleOk" />
    <base-table
      :title="$t('forms.periodoActivo.pluralTitle')"
      :records="records"
      @update:records="(val) => (records = val)"
      :table-columns="tableColumns"
      @update:table-columns="(val) => (tableColumns = val)"
      @add-row="addRow"
      @edit-row="editRow"
      @view-row="viewRow"
      @delete-row="deleteRow"
      @pageChanged="onChangePage"
      @reload-page="searchInList({ search: null })"
      @search="searchInList"
      :lang-dict="langDict"
    />
  </div>
</template>
<script>
import BaseTable from "@/components/Cards/BaseTable";
import Popup from "@/components/Cards/Popup.vue";
import { mapActions, mapGetters } from "vuex";
import { baseMixin } from "@/mixins/base";

export default {
  components: {
    BaseTable,
    Popup,
  },
  data() {
    return {
      records: [],
      tableColumns: [],
      langDict: "forms.periodoActivo.tableColumns",
    };
  },
  async created() {
    this.setShowOverlay(true);
    await this.searchInList({ search: null });
    this.setShowOverlay(false);
  },
  computed: {
    ...mapGetters(["getPageSize", "getPage"]),
  },
  mixins: [baseMixin],
  methods: {
    ...mapActions([
      "getPeriodoActivoList",
      "setPeriodoActivoList",
      "setTableColumns",
      "setPage",
      "setCount",
      "setShowOverlay",
      "deactivatePeriodoActivo",
    ]),
    async addRow() {
      this.$router.push({ name: "AddPeriodoActivo" });
    },
    async editRow(id) {
      this.$router.push({ name: "EditPeriodoActivo", params: { id } });
    },
    async viewRow(id) {
      this.$router.push({ name: "ViewPeriodoActivo", params: { id } });
    },
    async deleteRow(id) {
      try {
        const res = await this.deactivatePeriodoActivo({ id });

        if (res.status >= 200 && res.status < 300) {
          this.popupData = {
            type: "success",
            message: "La operación se completó con éxito",
          };
        }
        this.showPopup = true;
      } catch (err) {
        this.popupData = {
          type: "error",
          message: err,
        };
        this.showPopup = true;
      } finally {
        this.search = null;
        this.searchInList({ search: this.search });
        this.setShowOverlay(false);
      }
    },
    async searchInList({ search }) {
      this.setShowOverlay(true);

      const res = await this.getPeriodoActivoList({
        search: search,
        page: this.getPage,
        pageSize: this.getPageSize,
      });

      if (res.status === 200) {
        const { results, tableColumns, count } = await res.json();

        this.records = results;
        this.tableColumns = tableColumns;
        this.setCount(count);
        this.setPeriodoActivoList(results);
        this.setTableColumns(tableColumns);
      }
      this.setShowOverlay(false);
    },
    async onChangePage(page) {
      this.setPage(page);
      await this.searchInList({ search: null });
    },
  },
};
</script>
