<template>
  <div class="flex justify-between items-center">
    <div class="justify-center items-center lg:w-6/12 px-4">
      <permissions-card
        title="Disponibles"
        :permissions="$store.state.permissions.permissionsList"
        @permission-click="pendingToAddPermissions"
      />
      <p
        v-if="!isOnlyView" 
        class="text-center"
        @click="$store.dispatch('addAllPermissions')">
        {{ $t('forms.userGroup.form.addAllPermissions') }}
      </p>
    </div>
    <div class="fjustify-center items-center lg:w-2/12">
      <div 
        v-if="!isOnlyView"
        class="flex flex-col justify-center items-center"
      >
        <button 
          class="rounded-full border-none p-2 bg-indigo-500 text-white font-medium mb-2 mx-auto"
          @click.prevent="moveToAddedPermissions"
          >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-8 h-8">
            <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 19.5l7.5-7.5-7.5-7.5" />
          </svg>
        </button>
        <button 
          class="rounded-full border-none p-2 bg-indigo-500 text-white font-medium mx-auto"
          @click.prevent="moveToDeletededPermissions"
          >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-8 h-8">
            <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
          </svg>
        </button>
      </div>
    </div>
    <div class="justify-center items-center lg:w-6/12 px-4">
      <permissions-card
        title="Agregados"
        :permissions="$store.state.groups.addedPermissions"
        @permission-click="pendingToDeletePermissions"
      />
      <p
        v-if="!isOnlyView"
        class="text-center"
        @click="$store.dispatch('deleteAllPermissions')"
      >
      {{ $t('forms.userGroup.form.deleteAllPermissions') }}
    </p>
    </div>
  </div>
</template>
<script>
import PermissionsCard from '@/components/Cards/PermissionsCard.vue';
import { baseMixin } from '@/mixins/base';

export default {
  async created() {
    this.$store.dispatch('setAddedPermissions', [])
  },
  data () {
    return {
      permissions: [],
      pendingToAdd: [],
      pendingToDelete: []
    }
  },
  components: {
    PermissionsCard
  },
  mixins: [baseMixin],
  methods: {
    pendingToAddPermissions (permission) {
      this.pendingToAdd.push(permission);
    },
    pendingToDeletePermissions (permission) {
      this.pendingToDelete.push(permission);
    },
    moveToAddedPermissions () {
      this.$store.state.groups.addedPermissions = this.$store.state.groups.addedPermissions.concat(this.pendingToAdd)

      this.$store.state.permissions.permissionsList = this.$store.state.permissions.permissionsList.filter(item => {
        return !this.pendingToAdd.some(addedItem => addedItem.id === item.id);
      });
      this.$store.state.groups.addedPermissions.map(perm => perm.selected = false);
    },
    moveToDeletededPermissions () {
      this.$store.state.permissions.permissionsList = this.$store.state.permissions.permissionsList.concat(this.pendingToDelete)

      this.$store.state.groups.addedPermissions = this.$store.state.groups.addedPermissions.filter(item => {
        return !this.pendingToDelete.some(addedItem => addedItem.id === item.id);
      });

      this.$store.state.permissions.permissionsList.map(perm => perm.selected = false);
    }
  }
}
</script>