<template>
    <div class="container mx-auto px-4 h-full">
        <div class="flex content-center items-center justify-center h-full">
            <div class="w-full lg:w-4/12 px-4">
                <div
                    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0"
                    >
                    <div class="rounded-t mb-0 px-6 py-6">
                        <div class="text-center">
                            <h6 class="text-blueGray-500 text-sm font-bold">
                                {{ $t('message.login.verificationCode') }}
                            </h6>
                        </div>
                    </div>
                    <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
                      <form @submit.prevent="goToLogin()">
                        <div class="relative w-full mb-3">
                          <div v-html=translateMessage()>
                          </div>
                        </div>
                        <div class="text-center mt-6">
                          <button
                            class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                            type="submit"
                          >
                          {{ $t('message.login.continue') }}
                        </button>
                        </div>
                      </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  computed: {
    email () {
      return this.$store.state.auth.email
    }
  },
  methods: {
    translateMessage () {
      return this.$t('message.login.verificationCodeMessage', { email: '<b>' + this.email + '</b>' });
    },
    goToLogin () {
      this.$router.push({ name: 'Login'})
    }
  }
}
</script>