import PermissionService from "@/api/users/permissions";

const PermissionStore = {
  state: {
    permissionsList: [],
  },
  mutations: {
    setPermissionsList: (state, permissionsList) => state.permissionsList = permissionsList,
  },
  actions: {
    async getPermissionList({ commit, dispatch }, { search, page, pageSize }) {
      const res = await PermissionService.list(search, page, pageSize);
      const data = await res.json()

      switch (res.status) {
        case 401:
          dispatch("redirect", "/auth/login");
          break;
        case 404:
          dispatch("redirect", "/");
          break;
        default:
          commit('setPermissionsList', pageSize === -1 ? data : data.results)
          return res;
      }
    },
    setPermissionsList ({ commit }, list) {
      commit('setPermissionsList', list)
    }
  },
  getters: {
    getPermissionsListState: (state) => state.permissionsList
  },
};

export default PermissionStore;
