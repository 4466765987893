<template>
  <crud-base
    title="Encuesta"
    :popup-data="popupData"
    :show-popup="showPopup"
    @cancel="returnToList()"
    @add-register="save"
    @ok="returnToList"
  >
    <template v-slot:form>
      <div class="flex-auto lg:px-2 py-2 pt-4">
        <form>
          <div class="flex flex-wrap">
            <div class="w-full px-2">
              <custom-input
                :label="$t('forms.encuesta.fields.descripcion')"
                :placeholder="
                  $t('forms.encuesta.fieldsPlaceholder.descripcion')
                "
                :disabled="isOnlyView"
                type="text"
                v-model="encuesta.descripcion"
              />
            </div>
          </div>
          <div class="flex flex-wrap">
            <div class="w-full lg:w-6/12 px-2">
              <label
                class="w-full block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                {{ $t("forms.periodoActivo.title") }}
              </label>
              <periodo-activo-multiselect v-model="encuesta.periodoActivo" />
            </div>
            <div class="w-full lg:w-6/12 px-2">
              <label
                class="w-full block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                {{ $t("forms.idiomas.title") }}
              </label>
              <idiomas-multiselect v-model="encuesta.idioma" />
            </div>
          </div>
          <div class="flex flex-wrap mt-4">
            <div class="flex w-full px-2">
              <span
                class="block uppercase text-blueGray-600 text-xs font-bold py-3 mr-2"
              >
                Secciones
              </span>
              <div class="relative flex">
                <button
                  v-if="!isOnlyView"
                  class="bg-indigo-500 text-white shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none"
                  type="button"
                  @click.prevent="handleAddSeccion"
                >
                  <i class="fa fa-plus"></i>
                </button>
                <div
                  v-if="showTooltip"
                  class="top-full mt-2 p-2 bg-gray-800 text-white text-xs rounded ml-3"
                  :style="{
                    width: max - content,
                    backgroundColor: '#efdb3e',
                    color: 'black',
                  }"
                >
                  Necesita seleccionar un idioma
                </div>
              </div>
            </div>
          </div>
          <div
            class="flex flex-wrap mt-2 px-2"
            v-for="(item, idx) in encuesta.secciones"
            :key="idx"
          >
            <seccion-multiselect
              :model-value="item.seccion"
              @update:model-value="(value) => handleUpdateSeccion(idx, value)"
              @remove-seccion="handleRemoveSeccion(idx)"
            />
          </div>
        </form>
      </div>
    </template>
  </crud-base>
</template>

<script>
import CrudBase from "@/components/Cards/CrudBase.vue";
import { baseMixin } from "@/mixins/base";
import { mapActions } from "vuex";
import IdiomasMultiselect from "@/views/idiomas/IdiomasMultiselect.vue";
import PeriodoActivoMultiselect from "@/views/periodoActivo/PeriodoActivoMultiselect.vue";
import SeccionMultiselect from "@/views/secciones/SeccionMultiselect.vue";
import CustomInput from "@/components/Inputs/CustomInput.vue";

export default {
  components: {
    CrudBase,
    CustomInput,
    SeccionMultiselect,
    IdiomasMultiselect,
    PeriodoActivoMultiselect,
  },
  async created() {
    this.isLoading = !this.isLoading;
    this.setShowOverlay(this.isLoading);
    this.getPageTitle();

    this.$route.params.id ? await this.loadData() : this.initForm();

    this.showTooltip = true;

    if (this.isEditView || this.isOnlyView) {
      setTimeout(() => {
        this.isLoading = false;
        this.setShowOverlay(this.isLoading);
        this.isLoading = !this.isLoading;
      }, 10000);
    } else {
      this.isLoading = false;
      this.setShowOverlay(this.isLoading);
      this.isLoading = !this.isLoading;
    }
  },
  mixins: [baseMixin],
  data() {
    return {
      module: "encuestas",
      capitalizeModule: "Encuestas",
      listName: "ListEncuesta",
      encuesta: this.initForm(),
      showTooltip: false,
    };
  },
  methods: {
    ...mapActions([
      "setSeccionList",
      "setEncuesta",
      "setShowOverlay",
      "saveEncuesta",
      "getEncuestaById",
    ]),
    handleAddSeccion() {
      if (!this.encuesta.idioma) {
        this.showTooltip = true;
        setTimeout(() => {
          this.showTooltip = false;
        }, 2000); // Ocultar el tooltip después de 2 segundos
      } else {
        this.encuesta.secciones.push({ seccion: {} });
      }
    },
    initForm() {
      return {
        descripcion: null,
        idioma: null,
        periodoActivo: null,
        secciones: [],
      };
    },
    async loadData() {
      const data = await this.getEncuestaById({ id: this.$route.params.id });
      this.encuesta = {
        ...data,
        descripcion: data.descripcion,
        idioma: data.idioma,
        periodoActivo: data.periodo_activo,
        secciones: data.secciones.map((sec) => ({
          seccion: sec.seccion,
        })),
      };
    },
    async save() {
      try {
        const form = {
          ...this.encuesta,
          periodo_activo: this.encuesta.periodoActivo?.id,
          idioma: this.encuesta?.idioma?.id,
          secciones: this.encuesta.secciones.map((sec) => ({
            seccion: sec.id,
          })),
        };

        this.setEncuesta(form);
        const res = await this.saveEncuesta();
        const data = await res.json();

        this.setShowOverlay(false);

        const isOk = res.status >= 200 && res.status <= 300;

        this.popupData = {
          show: true,
          type: isOk ? "success" : "error",
          title: isOk
            ? this.$t("popup.success.title")
            : this.$t("popup.error.title"),
          message: isOk
            ? this.$t("popup.success.message")
            : data.non_field_errors[0],
        };
        this.showPopup = true;
      } catch (error) {
        this.setShowOverlay(false);
        this.popupData = {
          show: true,
          type: "error",
          title: this.$t("popup.error.title"),
          message: this.$t("popup.error.message"),
        };
        this.showPopup = true;
      }
    },
    handleRemoveSeccion(index) {
      this.encuesta.secciones.splice(index, 1);
    },
    handleUpdateSeccion(index, value) {
      this.encuesta.secciones.splice(index, 1, value);
    },
  },
};
</script>

<style scoped>
.tooltip {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 0.5rem;
  padding: 0.5rem 1rem;
  background-color: rgba(0, 0, 0, 0.75);
  color: #fff;
  font-size: 0.875rem;
  border-radius: 0.375rem;
  z-index: 10;
}
</style>
