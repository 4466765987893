<template>
  <crud-base
    :title="title"
    :popup-data="popupData"
    :show-popup="showPopup"
    @cancel="returnToList()"
    @add-register="save"
    @ok="returnToList"
  >
    <template v-slot:form>
      <div class="flex-auto lg:px-2 py-2 pt-4">
        <form>
          <div class="flex flex-wrap">
            <div class="w-full lg:w-6/12 px-2">
              <label
                class="w-full block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                {{ $t("forms.periodoActivo.fields.periodo") }}
              </label>
              <periodo-multiselect v-model="periodoActivo.periodo" />
            </div>
            <div class="w-full lg:w-6/12 px-2">
              <label
                class="w-full block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                {{ $t("forms.negocio.title") }}
              </label>
              <negocio-periodo-activo-multiselect v-model="periodoActivo.rubro" />
            </div>
          </div>
        </form>
      </div>
    </template>
  </crud-base>
</template>
<script>
import CrudBase from "@/components/Cards/CrudBase.vue";
import { baseMixin } from "@/mixins/base";
import { mapActions } from "vuex";
import PeriodoMultiselect from "@/views/periodo/PeriodoMultiselect.vue";
import NegocioPeriodoActivoMultiselect from "@/views/periodoActivo/NegocioPeriodoActivoMultiselect.vue";

export default {
  components: {
    CrudBase,
    PeriodoMultiselect,
    NegocioPeriodoActivoMultiselect,
  },
  data() {
    return {
      module: "periodoActivo",
      capitalizeModule: "PeriodoActivo",
      listName: "ListPeriodosActivos",
      periodoActivo: { periodo: null, rubro: null },
    };
  },
  mixins: [baseMixin],
  async created() {
    this.isLoading = !this.isLoading;
    this.setShowOverlay(this.isLoading);
    this.getPageTitle();

    // load data if edit or only read view
    // else do nothing
    this.$route.params.id ? await this.loadData() : this.initForm();

    this.isLoading = !this.isLoading;
    this.setShowOverlay(this.isLoading);
  },
  methods: {
    ...mapActions([
      "setShowOverlay",
      "setPeriodoActivo",
      "savePeriodoActivo",
      "getPeriodoActivoById",
    ]),
    initForm() {
      this.periodoActivo = { periodo: null, rubro: null };
    },
    async save() {
      try {
        this.setShowOverlay(true);

        this.periodoActivo.periodo = this.periodoActivo?.periodo?.id;
        this.periodoActivo.rubro = this.periodoActivo?.rubro?.id;
        this.setPeriodoActivo(this.periodoActivo);

        const res = await this.savePeriodoActivo();
        const data = await res.json();

        this.setShowOverlay(false);
        const isOk = res.status >= 200 && res.status <= 300;

        this.popupData = {
          show: true,
          type: isOk ? "success" : "error",
          title: isOk
            ? this.$t("popup.success.title")
            : this.$t("popup.error.title"),
          message: isOk
            ? this.$t("popup.success.message")
            : data.non_field_errors[0],
        };
        this.showPopup = true;
      } catch (error) {
        this.setShowOverlay(false);
        this.popupData = {
          show: true,
          type: "error",
          title: this.$t("popup.error.title"),
          message: this.$t("popup.error.message"),
        };
        this.showPopup = true;
      }
    },
    async loadData() {
      try {
        this.periodoActivo = await this.getPeriodoActivoById({
          id: this.$route.params.id,
        });
      } catch (error) {
        throw new Error(error);
      }
    },
  },
};
</script>
<style>
.invalid {
  color: red;
}
</style>
