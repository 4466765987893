<template>
  <div
    v-if="isVisible"
    class="flex flex-col h-full w-full overflow-y-auto pb-2"
  >
    <h2 class="text-blueGray-600 font-bold py-4 uppercase">
      {{ seccion.descripcion }}
    </h2>
    <div class="w-full px-2">
      <div
        v-for="(pregunta, idx) in preguntasFiltradas"
        :key="`${pregunta.id}`"
        class="col-span-1 pl-2 pr-2"
      >
        <div>
          <p class="uppercase text-sm font-bold text-justify py-2">
            <span class="mr-2">{{ idx + 1 }}.</span>
            {{ pregunta.descripcion }}
          </p>
          <ul
            v-if="
              (pregunta.tipo_respuesta === 1 && pregunta.multiple) ||
              pregunta.tipo_respuesta === 2
            "
          >
            <li
              class="ml-3 py-1"
              v-for="respuesta in pregunta.respuestas"
              :key="respuesta.id"
            >
              <input
                type="checkbox"
                class="border-0 px-3 py-3 placeholder-blueGray-300 uppercase text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150 mr-2 ml-3"
                :value="respuesta.id"
                :checked="isChecked(idx, respuesta)"
                :disabled="isOnlyView"
                @change="toggleCheckbox(idx, pregunta, respuesta.id)"
              />
              <label>{{ capitalizeText(respuesta.descripcion) }}</label>
            </li>
          </ul>
          <multiselect
            v-else-if="pregunta.tipo_respuesta === 1 && !pregunta.multiple"
            v-model="respuestaSeleccionada.preguntas[idx].respuesta"
            :options="pregunta.respuestas"
            :multiple="false"
            :placeholder="$t('multiselect.placeholder')"
            :selectLabel="$t('multiselect.selectLabel')"
            :disabled="isOnlyView"
            label="descripcion"
            track-by="id"
            @change="
              emitirRespuesta(idx, pregunta, respuestaSeleccionada[pregunta.id])
            "
          />
          <div class="flex w-full" v-else>
            <input
              v-model="respuestaSeleccionada.preguntas[idx].respuesta"
              :type="getInputType(pregunta.tipo_respuesta)"
              :placeholder="getPlaceholder(pregunta.tipo_respuesta)"
              :disabled="isOnlyView"
              class="w-full border-0 px-3 py-3 placeholder-blueGray-300 uppercase text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150 mr-2 ml-3"
              @input="
                emitirRespuesta(
                  idx,
                  pregunta,
                  respuestaSeleccionada.preguntas[idx].respuesta
                )
              "
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import { baseMixin } from "@/mixins/base";

export default {
  name: "WizardStep",
  mixins: [baseMixin],
  components: {
    Multiselect,
  },
  props: {
    stepNumber: {
      type: Number,
      required: true,
    },
    currentStep: {
      type: Number,
      required: true,
    },
    seccion: {
      type: Object,
      required: true,
    },
    respuestas: {
      type: Object,
      required: true,
    },
    isOnlyView: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      respuestaSeleccionada: { ...this.respuestas },
    };
  },
  computed: {
    isVisible() {
      return this.stepNumber === this.currentStep;
    },
    preguntasFiltradas() {
      return this.seccion.preguntas.filter(
        (pregunta) => !this.ocultar(pregunta)
      );
    },
  },
  methods: {
    capitalizeText(value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0)?.toUpperCase() + value.slice(1)?.toLowerCase();
    },
    getInputType(tipo_respuesta) {
      let type = "text";
      switch (tipo_respuesta) {
        case 3:
          type = "text";
          break;
        case 2:
          type = "number";
          break;
        case 1:
          type = "checkbox";
          break;
        default:
          type = tipo_respuesta.toLowerCase();
          break;
      }
      return type;
    },
    getPlaceholder(tipo_respuesta) {
      switch (tipo_respuesta) {
        case "TEXT":
          return "Ingrese su respuesta";
        case "NUMBER":
          return "Ingrese un número";
        default:
          return "";
      }
    },
    isChecked(idx, respuesta) {
      return this.respuestas.preguntas[idx].respuesta?.includes(respuesta.id);
    },
    toggleCheckbox(idx, pregunta, respuesta) {
      // Asegúrate de que 'listaRespuestas.respuesta' es un array antes de operar con él
      let listaRespuestas = this.respuestaSeleccionada.preguntas[idx];
      if (!Array.isArray(listaRespuestas.respuesta)) {
        listaRespuestas.respuesta = []; // Inicializar como array si no lo es
      }

      const index = listaRespuestas.respuesta.indexOf(respuesta);

      if (index > -1) {
        listaRespuestas.respuesta.splice(index, 1);
      } else {
        listaRespuestas.respuesta.push(respuesta);
      }

      this.emitirRespuesta(idx, pregunta, listaRespuestas.respuesta);
    },
    emitirRespuesta(idx, pregunta, respuesta) {
      let respuestasModificadas = this.respuestas;

      respuestasModificadas.preguntas[idx] = {
        id: pregunta.id,
        tipo_respuesta: pregunta.tipo_respuesta,
        respuesta: respuesta,
      };

      this.$emit("update:respuestas", respuestasModificadas);
      this.$forceUpdate();
    },
    ocultar(pregunta) {
      if (pregunta.padre) {
        // Busca la pregunta padre
        const padrePregunta = this.seccion.preguntas.find(
          (pr) => pr.id === pregunta.padre
        );

        // Asegúrate de que la pregunta padre exista antes de acceder a sus propiedades
        if (!padrePregunta) {
          console.warn(`Pregunta padre con ID ${pregunta.padre} no encontrada`);
          return false;
        }

        // Busca la respuesta seleccionada de la pregunta padre
        const respuestaPadre = this.respuestaSeleccionada.preguntas.find(
          (pr) => pr.id === padrePregunta.id
        );

        // Asegúrate de que la respuesta del padre exista antes de acceder a sus propiedades
        if (
          respuestaPadre &&
          respuestaPadre.respuesta &&
          respuestaPadre.respuesta.descripcion
        ) {
          return (
            respuestaPadre.respuesta.descripcion.toUpperCase() === "NO APLICA"
          );
        }
      }

      // Si no hay padre o no aplica la lógica, no ocultar la pregunta
      return false;
    },
  },
  watch: {
    respuestas: {
      handler(newVal) {
        this.respuestaSeleccionada = { ...newVal };
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style scoped>
/* Estilos específicos del paso */
.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px; /* Espacio entre columnas */
}
</style>
