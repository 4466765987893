<template>
  <div>
    <a
      class="text-blueGray-500 py-1 px-3"
      ref="btnDropdownRef"
      @click="toggleDropdown($event)"
    >
      <i class="fas fa-ellipsis-v"></i>
    </a>
    <div
      ref="popoverDropdownRef"
      class="bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48"
      v-bind:class="{
        hidden: !dropdownPopoverShow,
        block: dropdownPopoverShow,
      }"
    >
      <a
        v-if="showEditBtn"
        href="javascript:void(0);"
        class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
        @click.prevent.stop="dropdownPopoverShow=false & $emit('edit-row')"
      >
        {{ $t('btnLabels.edit') }}
      </a>
      <a
        v-if="showViewBtn"
        href="javascript:void(0);"
        class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
        @click.prevent.stop="dropdownPopoverShow=false & $emit('view-row')"
      >
        {{ $t('btnLabels.view') }}
      </a>
      <a
        v-if="showDeleteBtn"
        href="javascript:void(0);"
        class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
        @click.prevent.stop="dropdownPopoverShow=false & $emit('delete-row')"
      >
        {{ $t('btnLabels.delete') }}
      </a>
    </div>
  </div>
</template>
<script>
import { createPopper } from "@popperjs/core";

export default {
  props: {
    showEditBtn: {
      type: Boolean,
      default:true
    },
    showViewBtn: {
      type: Boolean,
      default:true
    },
    showDeleteBtn: {
      type: Boolean,
      default:true
    },
  },
  data() {
    return {
      dropdownPopoverShow: false,
    };
  },
  methods: {
    toggleDropdown: function (event) {
      event.preventDefault();
      if (this.dropdownPopoverShow) {
        this.dropdownPopoverShow = false;
      } else {
        this.dropdownPopoverShow = true;
        createPopper(this.$refs.btnDropdownRef, this.$refs.popoverDropdownRef, {
          placement: "bottom-start",
        });
      }
    }
  },
  emits: ['edit-row', 'view-row', 'delete-row']
};
</script>
