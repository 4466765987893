import EmpresaService from "@/api/empresas/empresas";

const EmpresaStore = {
  state: {
    empresaList: [],
    form: {
      razon_social: null,
      ruc_ci: null,
      direccion: null,
      rubro: null,
      segmento: null,
    },
  },
  mutations: {
    SET_EMPRESA_LIST: (state, empresaList) => (state.empresaList = empresaList),
    SET_EMPRESA: (state, empresa) => (state.form = empresa),
  },
  actions: {
    async getEmpresaList({ dispatch }, { search, page, pageSize }) {
      const res = await EmpresaService.list(search, page, pageSize);

      switch (res.status) {
        case 401:
          dispatch("redirect", "/auth/login");
          break;
        case 404:
          dispatch("redirect", "/");
          break;
        default:
          return res;
      }
    },
    async saveEmpresa({ state, dispatch }) {
      const form = {
        ...state.form,
        rubro: state.form?.rubro?.id,
        segmento: state.form?.segmento?.id,
        origen: state.form?.origen?.id,
      };
      const res = form.id
        ? await EmpresaService.edit(form)
        : await EmpresaService.create(form);

      switch (res.status) {
        case 401:
          dispatch("redirect", "/auth/login");
          break;
        case 404:
          dispatch("redirect", "/");
          break;
        default:
          return res;
      }
    },
    async getEmpresaById({ commit, dispatch }, { id }) {
      const res = await EmpresaService.getById({ id });
      let data = null;

      switch (res.status) {
        case 401:
          dispatch("redirect", "/auth/login");
          break;
        case 404:
          dispatch("redirect", "/");
          break;
        default:
          data = await res.json();
          commit("SET_EMPRESA", data);

          return data;
      }
    },
    setEmpresaList({ commit }, list) {
      commit("SET_EMPRESA_LIST", list);
    },
    async deactivateEmpresa({ dispatch }, { id }) {
      const res = await EmpresaService.deactivate({ id });

      switch (res.status) {
        case 401:
          dispatch("redirect", "/auth/login");
          break;
        case 404:
          dispatch("redirect", "/");
          break;
        default:
          return res;
      }
    },
    setEmpresa({ commit }, data) {
      commit("SET_EMPRESA", data);
    },
  },
  getters: {
    getEmpresaListState: (state) => state.empresaList,
  },
};

export default EmpresaStore;
