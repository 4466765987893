import Cookies from "js-cookie";
import AuthAPI from "@/api/auth/auth";
import Base from "@/api/base";
import router from "@/routers";

const Auth = {
  state: {
    user: null,
    email: null,
    isAdminUser: false,
  },
  mutations: {
    setUser: (state, user) => (state.user = user),
    clearUser: (state) => (state.user = null),
    setEmail: (state, email) => (state.email = email),
    setAdminUser: (state, payload) => (state.isAdminUser = payload),
  },
  actions: {
    async login({ commit, dispatch }, { username, password }) {
      const response = await AuthAPI.login({ username, password });

      if (!response.ok) {
        const errorData = await response.json();

        throw new Error(
          errorData.non_field_errors
            ? errorData.non_field_errors
            : "Login failed"
        );
      }

      const data = await response.json();

      commit("setUser", { username, password });
      Cookies.set("_riart_accessToken", await data.access);
      Cookies.set("_riart_refreshToken", data.refresh);

      dispatch("redirect", "/");
    },
    logout({ commit, dispatch }) {
      Cookies.remove("_riart_accessToken");
      Cookies.remove("_riart_refreshToken");
      commit("clearUser");
      dispatch("redirect", "/auth/login");
    },
    async checkAdminStatus({ commit, dispatch }) {
        const tokenValido = await dispatch("verifyToken");

        if (!tokenValido) {
          dispatch("logout"); // Si no es posible renovar el token, se desloguea
          return;
        }

        const res = await Base.isAdminUser();

        if (!res.ok && res.status === 401) {
          dispatch("redirect", "/auth/login");
        }

        const data = await res.json();
        commit("setAdminUser", data.result); // Establecer si es admin
    },
    redirect(_, path) {
      router.push(path);
    },
    async sendEmailToRecoveryPassword({ commit, dispatch }, { email }) {
      commit("setEmail", email);
      dispatch("redirect", "/auth/verification_code");
      await AuthAPI.sendEmailToRecoveryPassword({ email });
    },
    async resetPassword(
      { dispatch },
      { password, verificationPassword, token }
    ) {
      const res = await AuthAPI.resetPassword({
        password,
        verificationPassword,
        token,
      });

      if (res.status === 200) {
        dispatch("redirect", "/auth/login");
      } else {
        // TODO: if failed
      }
    },
    async verifyToken({ dispatch }) {
      const isValid = await Base.verifyToken();

      if (!isValid) {
        const tokenRenovado = await Base.refreshAccessToken();

        if (tokenRenovado) {
          return true;
        } else {
          dispatch("logout"); // Si no se puede renovar, hacer logout
          return false;
        }
      }

      return true;
    }
  },
  getters: {
    getIsAdminUser(state) {
      return state.isAdminUser;
    },
  },
};

export default Auth;
